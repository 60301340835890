import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.scss';
import './variables.scss';

//Route Components
import AddInterviewTimes from './screens/AddInterviewTimes/AddInterviewTimes.js';
import AddJobsContainer from './screens/AddJobsContainer2/AddJobsContainer.js';
import Applicants from './screens/Applicants/Applicants.js';
import BillingLanding from './screens/BillingLanding/BillingLanding.js';
import CompanyProfile from './screens/CompanyProfile/CompanyProfile.js';
import ForgotPassword from './screens/ForgotPassword/ForgotPassword.js';
import InvitedLogin from './screens/Register/InviteRegister.js';
import JobsLanding from './screens/JobsLanding/JobsLanding.js';
import JobsLandingArchived from './screens/JobsLanding/JobsLandingArchived.js';
import Notifications from './screens/Notifications/Notifications.js';
import Register from './screens/Register/RegisterNew.js';
import ResetPassword from './screens/ResetPassword/ResetPassword.js';
import TeamLanding from './screens/TeamLanding/TeamLanding.js';
import InterviewTimes from './screens/InterviewTimes/InterviewTimes.js';
import TransactionHistory from './screens/TransactionHistory/TransactionHistory.js';
import UserSettings from './screens/UserSettings/UserSettings.js';

import Header from './screens/components/Header';
import Sidebar from './screens/components/Sidebar';
import DashboardLanding from './screens/components/DashboardLanding';
import NotFound from './screens/components/NotFound';
import SelectPlan from "./screens/SelectPlan/SelectPlan";
import SelectJobType from "./screens/SelectJobType/SelectJobType";
import SelectPaymentMethod from "./screens/SelectPaymentMethod/SelectPaymentMethod";
import ConfirmPurchase from "./screens/ConfirmPurchase/ConfirmPurchase";
import TransactionSuccess from "./screens/TransactionSuccess/TransactionSuccess";
import SelectPreviousPaymentMethod from "./screens/SelectPreviousPaymentMethod/SelectPreviousPaymentMethod";
import ApplicantDetails from "./screens/ApplicantDetails/ApplicantDetails";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urgentUpdate: false
    }
  }

  updateUrgentUpdate() {
    this.setState({urgentUpdate: !this.state.urgentUpdate});
  }

  render() {
    let showSidebar=false;
    if (this.props.history.location.pathname.indexOf('/dashboard') >= 0) {
      showSidebar=true;
    }

    return (
      <div className="App">
        {showSidebar && <Sidebar remove={true} history={this.props.history} urgentUpdate={this.state.urgentUpdate}/>}
        <Header history={this.props.history}/>
        <Switch>
          <Route exact path="/" component={Register}/>
          <Route path="/register/:referrer" component={Register}/>
          <Route path="/register" component={Register}/>
          <Route path="/invite" component={InvitedLogin}/>
          <Route exact path="/dashboard" component={DashboardLanding}/>
          <Route exact path="/dashboard/add-interview-times/:id" render={(props) => (<AddInterviewTimes  updateUrgentUpdate={this.updateUrgentUpdate.bind(this)} {...props}/>)}/>
          <Route path="/dashboard/applicants" render={(props) => (<Applicants updateUrgentUpdate={this.updateUrgentUpdate.bind(this)} {...props}/>)}/>
          <Route path="/dashboard/applicant-details/:applicant_id" render={(props) => (<ApplicantDetails updateUrgentUpdate={this.updateUrgentUpdate.bind(this)} {...props}/>)}/>
          <Route path="/dashboard/applicant-details/:applicant_id/cancelled" render={(props) => (<ApplicantDetails updateUrgentUpdate={this.updateUrgentUpdate.bind(this)} {...props}/>)}/>
          <Route path="/dashboard/add-job" component={AddJobsContainer}/>
          <Route path="/dashboard/select-job-type" component={SelectJobType}/>
          <Route path="/dashboard/copy-job/:jobId" component={AddJobsContainer}/>
          <Route path="/dashboard/company-profile" component={CompanyProfile}/>
          <Route path="/dashboard/jobs" component={JobsLanding}/>
          <Route path="/dashboard/archived-jobs" component={JobsLandingArchived}/>
          <Route path="/dashboard/team" component={TeamLanding}/>
          <Route exact path="/dashboard/billing" component={BillingLanding}/>
          <Route path="/dashboard/billing/select-plan" component={SelectPlan}/>
          <Route path="/dashboard/billing/select-payment-method" component={SelectPaymentMethod}/>
          <Route path="/dashboard/billing/select-previous-payment-method" component={SelectPreviousPaymentMethod}/>
          <Route path="/dashboard/billing/confirm-purchase" component={ConfirmPurchase}/>
          <Route path="/dashboard/billing/transaction-success" component={TransactionSuccess}/>
          <Route path="/dashboard/transaction-history" component={TransactionHistory}/>
          <Route path="/dashboard/user-settings" component={UserSettings}/>
          <Route path="/dashboard/interview-times" component={InterviewTimes}/>
          <Route path="/dashboard/edit-job/:jobId" component={AddJobsContainer}/>
          <Route path="/dashboard/view-job/:jobId" component={AddJobsContainer}/>
          <Route path="/dashboard/notifications" component={Notifications}/>
          <Route path="/reset-password" component={ResetPassword}/>
          <Route path="/forgot-password" component={ForgotPassword}/>
          <Route path="*" component={NotFound}/>
        </Switch>
      </div>
    );
  }
}

export default App;
