import React, { Component } from 'react';
import InnerHeader from '../components/InnerHeader.js'
import { connect } from 'react-redux';
import { crudAPI, subscriptionAPI } from '../helperFunctions/dataServices.js'
import { ENV_TYPE } from '../../constants';
import ScaleLoader from 'react-spinners/ScaleLoader';
import FontAwesomeIcon from 'react-fontawesome';
import SuccessModal from '../components/SuccessModal';
import DashboardTranslations from '../../DashboardTranslations.js';
import ErrorModal from '../components/ErrorModal';
import ReactGA from 'react-ga';

import plans from '../../images/select-plan.png';
import newPlans from '../../images/new-select-plan-3.jpg';

import 'react-overlay-loader/styles.css';
import './styles/billingPage.scss';
import {getErrorMessage} from "../../messages/errors";
import JobTypeImage from "../../images/job-type-image.png";

class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      gmoUrl: "",
      showSuccess: false,
      successMessage: "",
      showGMORedirection: false,
      subscriptionData: [],
      showCancelSuccess: false,
      isAdmin: true,
      showError: false,
      error: "",
      isFirstTime: false,
      showPaymentSuccessModal: false,
    }
  }

  componentDidMount() {
    if (this.props.location.search) {
      if (this.props.location.search.indexOf("?payment=true") > -1) {
        this.setState({showPaymentSuccessModal: true, successMessage: DashboardTranslations.billing_page_trial_plan_payment_without_auto_renewal_success_message})
      }
      else if (this.props.location.search.indexOf("?payment=false") > -1) {
        this.setState({showError: true, error: getErrorMessage('billing_page_trial_plan_payment_failed_message')})
      }
    }
    this.subscription()
    if (ENV_TYPE === "PROD") {
      this.initializeReactGA()
    }
  }

  initializeReactGA() {
    ReactGA.initialize('UA-104174852-1');
    ReactGA.pageview('/dashboard/billing');
  }

  handleClose = () => {
    this.setState({ showSuccess: false, successMessage: '' });
  };

  handleSpecialClose() {
    window.location.reload()
  }

  closePaymentSuccess() {
    this.setState({showPaymentSuccessModal: false})
  }

  paymentSuccessModalRedirect() {
    this.props.history.push('/dashboard/jobs')
  }

  subscription() {
    this.setState({loading: true})
    subscriptionAPI().then((response) => {
    	if (response && response.status >= 200 && response.status < 300) {
        this.setState({loading: false, subscriptionData: response.data, isAdmin: response.data.is_admin})
    	}
    	else {
    		this.setState({loading: false})
    	}
    })
    .catch((e) => {
      this.setState({loading: false})
      if (e && e.response.status === 403) {
        this.setState({isAdmin: false})
      }
    })
  }

  editCC() {
    this.setState({loading: true});
    crudAPI().then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({gmoUrl: response.data.gmo_url, isFirstTime: response.data.is_first_time_click});
        window.location.href = response.data.gmo_url;
      }
    });
  }

  crud(firstTime) {
    if (firstTime) {
      this.props.history.push({
        pathname: '/dashboard/billing/select-plan',
        state: {
          subscription_status: this.state.subscriptionData.subscription_status,
          is_cc_added_in_gmo: this.state.subscriptionData.is_cc_added_in_gmo,
          verification_status: this.state.subscriptionData.verification_status,
          paidBy: (this.state.subscriptionData.subscription_status === 'IS') ? this.state.subscriptionData.subscription_data.paid_by : null,
          currSubscriptionData: this.state.subscriptionData.subscription_data
        }
      });
    }
  }

  paymentTermsRedirection() {
    var win = window.open("https://www.workjapan.jp/price", '_blank');
    win.focus();
  }

  handleErrorClose = () => {
    this.setState({showError: false, error: ''})
  }

  onClickAddEditPayment() {
    this.crud(true)
  }

  convert(timestamp) {
    const date = new Date(timestamp*1000);
    const cDate = date.getDate();
    const cMonth = date.getMonth();
    const cYear = date.getFullYear();

    if (timestamp !== null) {
      return [cYear, "年", cMonth+1, "月", cDate, "日"].join('');
    }
    else {
      return "";
    }
  }

  render() {

    var { loading, subscriptionData, isAdmin, showSuccess, successMessage, showPaymentSuccessModal, showError, error,
      showCancelSuccess } = this.state
    if (subscriptionData && subscriptionData.subscription_data && subscriptionData.subscription_data.plan) {
      for (var i = 5; i >= 1; i--) {
        var application
        if (i <= 5-parseInt(subscriptionData.subscription_data.applications)) {
          application = <FontAwesomeIcon
                          key={"application" + i}
                          name="user" className="blue-user-icon"/>
        }
        else {
          application = <FontAwesomeIcon
                          key={"application" + i}
                          name="user" className="gray-user-icon"/>
        }
      }
    }

    var daysRemaining = 0;
    if (subscriptionData && subscriptionData.subscription_data) {
      const maxPlanDays = subscriptionData.subscription_data.plan.max_days;
      daysRemaining = maxPlanDays-parseInt(subscriptionData.subscription_data.days_consumed)
      for (var j = maxPlanDays; j >= 1; j--) {
        var day
        if (j <= daysRemaining) {
          day = <div key={"day" + j} className="blue-cell" />
        }
        else {
          day = <div key={"day" + j} className="gray-cell" />
        }
      }
    }

    var verificationStatus = "NA"
    if (subscriptionData && subscriptionData.verification_status) {
      verificationStatus = subscriptionData.verification_status
    }

    return (
      <div className="billing-page-container">
        <InnerHeader
          primary={true}
          history={this.props.history}
          headerText={DashboardTranslations.billing_page_header_text}
          buttonOneText={DashboardTranslations.billing_page_terms_redirect_button_text}
          buttonOneClick={this.paymentTermsRedirection.bind(this)}
        />
        <ScaleLoader sizeUnit={"px"} height={50}
          width={4} color={'#007AFF'} loading={loading}
        />

        {!isAdmin && subscriptionData && !loading &&
          <div className="status-description-text">{DashboardTranslations.billing_page_not_admin_error_message}</div>
        }

        {/*Invoice verification status*/}
        {isAdmin && !loading && subscriptionData && subscriptionData.subscription_status !== "IS" &&
          <div>
            {verificationStatus === "PENDING" &&
              <div className="before-trial-text" style={{whiteSpace: "pre-line", color: "#EF1119"}}>
                {DashboardTranslations.billing_page_invoice_payment_under_verification_text}
              </div>
            }
            {verificationStatus === "REJECTED" &&
              <div className="before-trial-text">
                {DashboardTranslations.billing_page_invoice_payment_rejected_text}
              </div>
            }
          </div>
        }

        {/*In Subscription*/}
        {!loading && subscriptionData &&
          <div>
            <div className="billing-page-inner width-auto" style={{margin: subscriptionData && subscriptionData.subscription_status === "IS" ? "20px auto" : "20px auto"}}>
              {subscriptionData.subscription_status === "IS" && <div className="single-box">
                <div className="single-box-header">
                  {DashboardTranslations.billing_page_current_pack_header}
                </div>
                <div className="details-container">
                  {subscriptionData && subscriptionData.subscription_data && subscriptionData.subscription_data.plan.name_jp}
                  <div className="description">
                    ご利用可能な日数は残り{daysRemaining}日です<br/>
                    ご利用期間 {this.convert(subscriptionData.subscription_data.start_date_timestamp)} 〜 {this.convert(subscriptionData.subscription_data.expiry_date_timestamp)}
                    {subscriptionData.subscription_data.order_date_timestamp && <div>
                      ご発注日　{this.convert(subscriptionData.subscription_data.order_date_timestamp)}
                    </div>}
                  </div>
                </div>
              </div>}

              {(subscriptionData.has_unprocessed_fsq) && <div className="single-box">
                <div className="single-box-header">
                  次のご利用プラン
                </div>
                <div className="details-container">
                  {subscriptionData.next_subscription_data.plan.name_jp}
                  {(subscriptionData.next_subscription_data.starts_on) && <div className="description">
                    ご利用期間　{this.convert(subscriptionData.next_subscription_data.starts_on)} 〜 {this.convert(subscriptionData.next_subscription_data.expires_on)}<br/>
                    ご発注日　{this.convert(subscriptionData.next_subscription_data.ordered_on)}
                    </div>}

                </div>
              </div>}

              {(subscriptionData.subscription_status === "IS") && <div className="single-box">
                <div className="single-box-header">
                  お支払方法
                </div>
                <div className="details-container">
                  {subscriptionData.subscription_status !== "BT" && <div>{subscriptionData && subscriptionData.subscription_data && subscriptionData.subscription_data.paid_by === 'CC' ? "クレジットカード払い" : "請求書払い"}</div>}
                  <div className="description">

                    {subscriptionData && subscriptionData.subscription_data.paid_by === 'CC' && subscriptionData.is_cc_added_in_gmo &&
                    <div className="section-link" onClick={() => this.editCC()}>
                      クレジットカード情報を編集する
                    </div>
                    }
                  </div>
                </div>
              </div>}

              {(subscriptionData.subscription_status !== "IS" && !subscriptionData.has_unprocessed_fsq) &&
              <div className="transactions-button-container">
                <div className="section-link" onClick={() => this.onClickAddEditPayment()}>
                  プランを購入する
                </div>
              </div>
              }

              {(subscriptionData.subscription_status !== "IS" && subscriptionData.is_cc_added_in_gmo) &&
                  <div className="transactions-button-container">
                    <div className="section-link" onClick={() => this.editCC()}>
                      クレジットカード情報を編集する
                    </div>
              </div>}

              {isAdmin && <div className="transactions-button-container">
                <div className="section-link" onClick={() => this.props.history.push("/dashboard/transaction-history")}>
                  {DashboardTranslations.billing_page_transactions_redirect_button_text}
                </div>
              </div>}

              {
                (subscriptionData.subscription_status !== "IS") &&
                <div>
                  <br/>
                  <div className='billing-body-container-2'>
                    <div className='billing-heading-text'>
                      料金プラン
                    </div>
                    <br/>
                    <img
                      src={newPlans}
                      alt="close"
                    />
                  </div>
                  <div className='divider-line'></div>
                  <div className='billing-body-container-2'>
                    <div className='billing-heading-text'>
                      プラン詳細
                    </div>
                    <br/>
                    <div>
                      <img src={JobTypeImage} alt='Job Type Info' style={{width: '100%'}} />
                    </div>

                  </div>

                  <div
                    className='billing-container-2'
                    style={{
                      paddingBottom: '20px',
                      textAlign: 'justify',
                      fontSize: '14px',
                    }}
                  >
                    ♢<span style={{fontWeight: '500'}}>掲載について</span>
                    <div style={{marginLeft: '13px'}}>※１記事に「募集職種＋雇用形態＋勤務地」が各一つずつ掲載できます。</div>
                    <div style={{marginLeft: '26px'}}>例１）正社員とアルバイトを募集する場合は2記事となります。</div>
                    <div style={{marginLeft: '26px'}}>例２）同じ勤務地でも、皿洗い・ウエイターなど２職種の募集する場合は2記事となります。</div>
                    <div style={{marginLeft: '13px'}}>※プラン間の記事の流用はできません。</div>
                    {/*<div style={{marginLeft: '13px'}}>※2つのプランの同時掲載はできませんが、掲載中に別プランの購入はできます。</div>*/}

                    <br/>
                    <br/>
                    ♢<span style={{fontWeight: '500'}}>お支払い方法</span>
                    <div style={{marginLeft: '13px'}}>お支払はクレジットカードまたは請求書払いがあります。</div>
                    <div style={{marginLeft: '13px'}}>クレジットカード払なら即掲載開始できます。請求書払いの場合はご利用にあたり審査が必要です。</div>
                    <br/>
                    <br/>
                    ♢<span style={{fontWeight: '500'}}>その他の注意事項</span>
                    <br/>
                    <div style={{marginLeft: '13px'}}>
                      <div>※料金は一括払いとなります。</div>
                      <div>※購入したプランの変更やキャンセルはできません。</div>
                      <div>※WORK JAPANは求人サイトであり、適性や能力等を保証するものではありません。</div>
                      <div style={{marginLeft: '13px'}}>また、紹介フィーをいただく事はありません。</div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        }

        <SuccessModal successMessage={successMessage} handleClose={this.handleClose.bind(this)} showSuccess={showSuccess}/>
        <SuccessModal successMessage={successMessage} handleClose={this.handleSpecialClose.bind(this)} showSuccess={showCancelSuccess}/>
        <SuccessModal successMessage={successMessage} handleClose={this.closePaymentSuccess.bind(this)} handleCloseRedirect={this.paymentSuccessModalRedirect.bind(this)} showSuccess={showPaymentSuccessModal}/>
        <ErrorModal error={error} handleClose={this.handleErrorClose.bind(this)} showError={showError}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token,
        isAdmin: state.user.isAdmin,
    }
};

Billing = connect(mapStateToProps)(Billing);

export default Billing;
