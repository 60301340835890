const disabledFields = {
  SSV: ['hourlySalaryMin', 'hourlySalaryMax'],
  FT: [
    'hourlySalaryMin', 'hourlySalaryMax',
    'ssvIndustries', 'acceptanceCertificates'
  ],
  PT2: [
    'ssvIndustries', 'acceptanceCertificates',
    'monthlySalaryMin', 'monthlySalaryMax', 'annualSalaryMin',
    'annualSalaryMax', 'bonusPaidPerYear', 'raisePerYear', 'holidays',
    'socialInsuranceProvided'
  ],
  PT1: [
    'position', 'jobDetail', 'preferredExperienceYear', 'preferredQualification',
    'preferredSkills', 'preferredCertificates', 'preferredJPLevel',
    'preferredEnLevel', 'preferredVisaTypes', 'ssvIndustries', 'acceptanceCertificates',
    'willSupportVisaChange', 'monthlySalaryMin', 'monthlySalaryMax', 'annualSalaryMin',
    'annualSalaryMax', 'bonusPaidPerYear', 'raisePerYear', 'overtimeHoursPerMonth',
    'remoteWork',  'holidays', 'socialInsuranceProvided', 'company', 'companyIndustryType',
    'companyNameEn', 'companyNameJp', 'companyEmployee', 'nationalities', 'website'
  ]
}


const mandatoryFields = {
  SSV: [
    'work', 'inCharge', 'preferredJPLevel', 'willSupportVisaChange', 'monthlySalaryMin', 'monthlySalaryMax',
    'annualSalaryMin', 'annualSalaryMax', 'transportationCovered', 'workHours',
    'companyNameJp', 'companyNameEn', 'companyIndustryType', 'companyEmployee'
  ],
  FT: [
    'work', 'inCharge', 'preferredJPLevel', 'willSupportVisaChange', 'monthlySalaryMin', 'monthlySalaryMax',
    'annualSalaryMin', 'annualSalaryMax', 'transportationCovered', 'workHours',
    'companyNameJp', 'companyNameEn', 'companyIndustryType', 'companyEmployee'
  ],
  PT1: [
    'work', 'inCharge', 'salaryMin', 'salaryMax',
    'transportationCovered', 'workHours',
  ],
  PT2: [
    'work', 'inCharge', 'preferredJPLevel', 'willSupportVisaChange', 'salaryMin', 'salaryMax',
    'transportationCovered', 'workHours', 'companyNameJp', 'companyNameEn', 'companyIndustryType',
    'companyEmployee'
  ]
}

export {disabledFields, mandatoryFields}
