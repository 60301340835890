import axios from "axios"
import {store} from "../../store.js"
import {API_ROOT} from '../../constants';

var storedData = store.getState()

store.subscribe( () => {
  storedData = store.getState()
});

var headers = {
		"Content-Type": "application/json",
		"Authorization": "Token "+ storedData.user.token
}

var headerWithoutToken = {
	"Content-Type": "application/json",
}

export async function jobs () {
	store.subscribe( () => {
	  storedData = store.getState()
	});
	headers = {
			"Content-Type": "application/json",
			"Authorization": "Token "+ storedData.user.token
	}
	try {
		var res = await axios.get(API_ROOT + 'api/v3/employer/jobs/', {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function job (id) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.get(API_ROOT + 'api/v3/employer/jobs/'+id+'/', {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function getDraftJob () {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.get(API_ROOT + 'api/v3/employer/draft-jobs/', {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function checkIfLocationExists(brandNameJp, branch) {
	store.subscribe( () => {
		storedData = store.getState()
	});
	headers = {
		"Content-Type": "application/json",
		"Authorization": "Token "+ storedData.user.token
	}

	try {
		return await axios.get(
			API_ROOT + 'api/v3/employer/locations/check_if_exists/',
			{
				headers: headers,
				params: {
					brand_name_jp: brandNameJp,
					branch: branch
				}
			}
		)
	} catch (e) {
		return e
	}
}


export async function getInterviewsAPI () {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.get(API_ROOT + 'api/v3/employer/interview_slots/', {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function fetchApplicantDetailsAPI (id) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.get(API_ROOT + 'api/v3/employer/job_applications/'+id+'/', {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function fetchCancelledApplicantDetailsAPI (id) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.get(API_ROOT + 'api/v3/employer/cancelled_job_applications/'+id+'/', {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function fetchAllApplicationsAPI() {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		return await axios.get(API_ROOT + 'api/v4/employer/job_applications/', {headers: headers})
	} catch (e) {
		return e
	}
}

export async function updateSlots (id) {
  var intValue = parseInt(id)
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.get(API_ROOT + 'api/v3/employer/interviews/request_new_slot/?job_application_id='+intValue, {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function locations () {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.get(API_ROOT + 'api/v2/employer/locations/', {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function getPostalCode (value) {
	var intValue = parseInt(value)
	try {
		var res = await axios.get(API_ROOT + 'api/v2/employer/postcode_address/?code='+intValue, {headers: headerWithoutToken})
	 	return res
	} catch (e) {
		return e
	}
}

export async function getWardDetails (value) {
	var intValue = parseInt(value)

	try {
		var res = await axios.get(API_ROOT + 'api/v2/employer/wards/?city='+intValue, {headers: headerWithoutToken})
	 	return res
	} catch (e) {
		return e
	}
}

export async function getCitiesDetails (value) {
	var intValue = parseInt(value)

	try {
		var res = await axios.get(API_ROOT + 'api/v2/employer/cities/?prefecture='+intValue, {headers: headerWithoutToken})
	 	return res
	} catch (e) {
		return e
	}
}

export async function initialize () {
  store.subscribe( () => {
    storedData = store.getState()
  });

	try {
		var res = await axios.get(API_ROOT + 'api/v3/employer/initialize_register/', {headers: headerWithoutToken})
	 	return res
	} catch (e) {
		return e
	}
}

export async function initializeJob () {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		return await axios.get(API_ROOT + 'api/v2/employer/initialize_job/', {headers: headers})
	} catch (e) {
		return e
	}
}

export async function fetchMemberDetailsAPI () {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.get(API_ROOT + 'api/v3/employer/members/', {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function fetchUserDetails () {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.get(API_ROOT + 'api/v3/employer/user_profile/', {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function resendVerificationCode () {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.get(API_ROOT + 'api/v3/employer/resend_verification_code/', {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function subscriptionAPI () {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		return await axios.get(API_ROOT + 'api/v3/employer/subscription/', {headers: headers})
	} catch (e) {
		return e
	}
}

export async function transactionHistoryAPI () {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.get(API_ROOT + 'api/v3/employer/transactions/', {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function crudAPI () {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		return await axios.get(API_ROOT + 'api/v3/employer/crud_cc/', {headers: headers})
	} catch (e) {
		return e
	}
}

export async function renewPayByInvoiceAPI () {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.get(API_ROOT + 'api/v3/employer/subscription/renew?pay_by_invoice=true', {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}


export async function addInterviewTimesAPI (payload) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.post(API_ROOT + 'api/v3/employer/interview_slots/', payload, {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function getInterviewSlots () {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.get(API_ROOT + 'api/v3/employer/interview_slots/', {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function getInterviewSlotsFilteredByInterviewer (id) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.get(API_ROOT + 'api/v3/employer/interview_slots/', {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}


export async function addJobAPI (payload) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.post(API_ROOT + 'api/v3/employer/jobs/', payload, {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function addDraftJobAPI (payload) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.post(API_ROOT + 'api/v3/employer/draft-jobs/', { data: payload }, {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}


export async function editJobAPI (payload, id) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.patch(API_ROOT + 'api/v3/employer/jobs/'+id+'/', payload, {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}


export async function addPicturesAPI (payload, id, isDraftView) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var url = API_ROOT + 'api/v3/employer/jobs/'+id+'/work_images/';
		if (isDraftView) {
			url = API_ROOT + 'api/v3/employer/draft-jobs/'+id+'/work_images/'
		}
		var res = await axios.post(url, payload, {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function deleteImage (jobId, payload) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await fetch(API_ROOT + 'api/v3/employer/jobs/'+jobId+'/work_images/',
      {body: JSON.stringify(payload), method: 'DELETE', headers: headers}
    )
	 	return res
	} catch (e) {
		return e
	}
}



export async function updateApplicantStatusAPI (payload, id) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.patch(API_ROOT + 'api/v3/employer/job_applications/'+id+'/', payload, {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function registerAPI (payload) {

	try {
		var res = await axios.post(API_ROOT + 'api/v3/employer/register/', payload, {headers: headerWithoutToken})
	 	return res
	} catch (e) {
		return e
	}
}

export async function registerEmployerAPI (payload) {

	try {
		var res = await axios.post(API_ROOT + 'api/v3/employer/initial_register/', payload, {headers: headerWithoutToken})
	 	return res
	} catch (e) {
		return e
	}
}

export async function addCompanyDetailsAPI (payload) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }
	try {
		var res = await axios.post(API_ROOT + 'api/v3/employer/register/', payload, {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function inviteeRegisterAPI (payload) {

	try {
		var res = await axios.post(API_ROOT + 'api/v3/employer/invitee_register/', payload, {headers: headerWithoutToken})
	 	return res
	} catch (e) {
		return e
	}
}

export async function loginAPI (payload) {

	try {
		var res = await axios.post(API_ROOT + 'api/v2/employer/login/', payload, {headers: headerWithoutToken})
	 	return res
	} catch (e) {
		return e
	}
}

export async function changeRoleAPI (payload, id) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.patch(API_ROOT + 'api/v2/employer/members/'+id+'/', payload, {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function removeMemberAPI (id) {
	store.subscribe( () => {
	  storedData = store.getState()
	});
	headers = {
		"Content-Type": "application/json",
		"Authorization": "Token "+ storedData.user.token
	}
  
	  try {
		  var res = await axios.delete(API_ROOT + 'api/v2/employer/members/'+id+'/', {headers: headers})
		   return res
	  } catch (e) {
		  return e
	  }
  }

export async function resendInvitationAPI(payload) {
	store.subscribe( () => {
		storedData = store.getState()
	});
	headers = {
		"Content-Type": "application/json",
		"Authorization": "Token "+ storedData.user.token
	}

	try {
		return await axios.post(API_ROOT + 'api/v3/employer/members/resend_invitation/', payload, {headers: headers})
	} catch (e) {
		return e
	}
}


export async function forgotPasswordAPI (payload) {
	try {
		var res = await axios.post(API_ROOT + 'api/v2/employer/forgot_password/', payload, {headers: headerWithoutToken})
	 	return res
	} catch (e) {
		return e
	}
}

export async function deleteSlotAPI (id) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.delete(API_ROOT + 'api/v3/employer/interviews/'+id+'/', {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function resetPasswordAPI (payload) {

	try {
		var res = await axios.post(API_ROOT + 'api/v2/employer/reset_password/', payload, {headers: headerWithoutToken})
	 	return res
	} catch (e) {
		return e
	}
}

export async function addMemberAPI (payload) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.post(API_ROOT + 'api/v3/employer/members/', payload, {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function verifyMobileNumberAPI (payload) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.post(API_ROOT + 'api/v3/employer/verify_contact_num/', payload, {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function updateUserDetailsAPI (payload) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.patch(API_ROOT + 'api/v3/employer/user_profile/', payload, {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function payByInvoiceAPI () {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.get(API_ROOT + 'api/v3/employer/request_pay_by_invoice/', {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function notificationDataAPI () {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.get(API_ROOT + 'api/v3/employer/notifications/', {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function notificationReadAPI (payload, id) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.patch(API_ROOT + 'api/v3/employer/notifications/'+id+'/', payload, {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}

export async function sendSMSToSeeker (payload, applicationId) {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		return await axios.post(
			API_ROOT + `api/v3/employer/job_applications/${applicationId}/messages_to_seeker/`,
			payload,
			{headers: headers})
	} catch (e) {
		return e
	}
}

export async function sendOnlineIVInfoToSeeker (payload, applicationId) {
	store.subscribe( () => {
	  storedData = store.getState()
	});
	headers = {
		"Content-Type": "application/json",
		"Authorization": "Token "+ storedData.user.token
	}
  
	  try {
		  return await axios.post(
			  API_ROOT + `api/v3/employer/job_applications/${applicationId}/online_iv_details/`,
			  payload,
			  {headers: headers})
	  } catch (e) {
		  return e
	  }
  }


export function downloadApplicantList () {
	store.subscribe( () => {
		storedData = store.getState()
	});
	const headers = {
		'Authorization': "Token " + storedData.user.token
	};

	fetch(API_ROOT + 'api/v4/employer/download-applicant-list/', {
		method: 'GET',
		headers: headers,
	})
		.then(response => response.blob())
		.then(blob => {
			const url = window.URL.createObjectURL(new Blob([blob]));
			const link = document.createElement('a');
			link.href = url;

			const today = new Date();
			const dd = String(today.getDate()).padStart(2, '0');
			const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
			const yyyy = today.getFullYear();

			link.setAttribute('download', `WORK JAPAN_data_${yyyy + mm + dd}.csv`);
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);
		})
		.catch(error => {
			console.error('Error downloading file:', error);
		});
}


export const downloadInvoice = (transactionId, isCC) => {
	store.subscribe( () => {storedData = store.getState()});
	const headers = {'Authorization': "Token " + storedData.user.token};
	let filename;

	fetch(API_ROOT + `api/v4/employer/download-invoice/?transaction_id=${transactionId}&is_cc=${isCC}`, {
		method: 'GET',
		headers: headers,
	})
		.then(response => {
			filename =  response.headers.get('Content-Disposition').split('filename=')[1];
			return response.blob()
		})
		.then((blob) => {
			const url = window.URL.createObjectURL(new Blob([blob]));
			const link = document.createElement('a');
			link.href = url;

			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);
		})
		.catch(error => {
			console.error('Error downloading file:', error);
		});
}


export async function getUrgentApplicationCount () {
	store.subscribe( () => {
		storedData = store.getState()
	});
	headers = {
		"Content-Type": "application/json",
		"Authorization": "Token "+ storedData.user.token
	}

	try {
		return await axios.get(
			API_ROOT + `api/v3/employer/job_applications/get_urgent_count/`,
			{headers: headers})
	} catch (e) {
		return e
	}
} 

export async function getFilterOptions () {
	store.subscribe( () => {
		storedData = store.getState()
	});
	headers = {
		"Content-Type": "application/json",
		"Authorization": "Token "+ storedData.user.token
	}

	try {
		return await axios.get(
			API_ROOT + `api/v3/employer/job_applications/get_filter_options/`,
			{headers: headers})
	} catch (e) {
		return e
	}
} 


export async function getOnlineIVInfo (applicationId) {
	store.subscribe( () => {
		storedData = store.getState()
	});
	headers = {
		"Content-Type": "application/json",
		"Authorization": "Token "+ storedData.user.token
	}

	try {
		return await axios.get(
			API_ROOT + `api/v3/employer/job_applications/${applicationId}/online_iv_details/`,
			{headers: headers})
	} catch (e) {
		return e
	}
} 


export async function agreeToTC () {
  store.subscribe( () => {
    storedData = store.getState()
  });
  headers = {
      "Content-Type": "application/json",
      "Authorization": "Token "+ storedData.user.token
  }

	try {
		var res = await axios.get(API_ROOT + 'api/v3/employer/tnc_agreed/', {headers: headers})
	 	return res
	} catch (e) {
		return e
	}
}



// this.setState({loading: true})
// job(id).then((response) => {
// 	if (response && response.status >= 200 && response.status < 300) {
// 		this.setState({singleJob: response.data, loading: false})
// 	}
// 	else {
// 		this.setState({loading: false})
// 	}
// })
