import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import '../styles/PopupModal.scss'


const PopupModal = (props) => (
  <div>
    <Dialog
      open={props.showModal}
      onClose={props.handleCancel}
      PaperProps={{
        style: { borderRadius: 8 }
      }}
    >
      <div className='popup-modal__container'>
        {props.popupIcon && <img src={props.popupIcon} alt={'Popup icon'} className='popup-modal__icon'/>}
        {props.title && <div className='popup-modal__title'>
          {props.title}
        </div>}
        {props.subtitle && <div className='popup-modal__subtitle'>
          {props.subtitle}
        </div>} 
        <div className='popup-modal__button-container'>
          {props.cancelText && props.handleCancel && <div 
            className='popup-modal__button popup-modal__cancel1'
            onClick={props.handleCancel}
          >
            {props.cancelText}
          </div>}
          {props.confirmText && props.handleConfirm && <div 
            className='popup-modal__button popup-modal__confirm1'
            onClick={props.handleConfirm}
          >
            {props.confirmText}
          </div>}
        </div>
      </div>
    </Dialog>
  </div>
);

export default PopupModal;
