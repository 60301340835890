import React, { Component } from 'react';
import { connect } from 'react-redux';
import InnerHeader from '../components/InnerHeader.js'
import ScaleLoader from 'react-spinners/ScaleLoader';
import DashboardTranslations from '../../DashboardTranslations.js';
import JobTypeImage from '../../images/job-type-image.png'
import SelectPlanImage from '../../images/select-plan.png'
import SelectNewPlanImage from '../../images/new-select-plan-3.jpg'
import SelectNewPlanImage4 from '../../images/new-select-plan-6.png'
import SelectNewPlanImage5 from '../../images/new-select-plan-5.png'

import { withStyles } from '@material-ui/core/styles';

import 'react-overlay-loader/styles.css';
import '../SelectPlan/styles/selectPlan.scss';

const useStyles = theme => ({
  table: {
    minWidth: 650,
  },
});

class SelectJobType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  selectJobType = (jobType) => {

    this.props.history.push({
      pathname: "/dashboard/add-job",
      state: {
        job_type: jobType
      }
    })

  };

  backArrowOnClick() {
    this.props.history.goBack();
  }

  render() {
    const {loading } = this.state;

    return (
        <div className="transaction-history-page-container margin-top-110">
          <InnerHeader
              primary={false}
              history={this.props.history}
              headerText={DashboardTranslations.select_job_type_page_header_text}
              backArrowOnClick={this.backArrowOnClick.bind(this)}
          />
          <ScaleLoader sizeUnit={"px"} height={50}
                       width={4} color={'#007AFF'} loading={loading}
          />
          <div className='select-plan-container-1'>
            記事数と購入プランを選んで記事を作成してください
          </div>
          <div className='select-plan-container-1 select-plan-container-1__small'>
            ※プラン間の記事の流用はできません。購入プランに該当する記事の作成が必要です 。
          </div>
          <div className='billing-body-container-2'>
            <div className='billing-heading-text'>
              <div className='billing-step-container'>1</div>
              記事数を決める
            </div>
            <div className='billing-step1-circle'>
                求人作成<br/>まで<br/>無料!!
            </div>
            <div className='billing-step1-container'>
              <div className='billing-step1-text1'>
                記事数は
              </div>
              <div className='billing-step1-text2'>
                勤務地数
                <div className='billing-step1-text2 billing-step1-text2__small'>
                  （ブランド名＋店舗名）
                </div>
              </div>
              <div className='billing-step1-text1'>
                X
              </div>
              <div className='billing-step1-text2'>
                職種数
                <div className='billing-step1-text2 billing-step1-text2__small'>
                  （ウエイター）
                </div>
              </div>
              <div className='billing-step1-text1'>
                で決定
              </div>
            </div>
          </div>
          {!loading && <div className='billing-body-container-2'>
            <div className='billing-heading-text'>
              <div className='billing-step-container'>2</div>
                購入プランを選んで、記事を作成する
              </div>
              <div className="transaction-page-description">
              <div>
                <div className='transaction-page-container'>
                <div className='transaction-page-column'>
                    <div>
                      <div className='transaction-page-column-heading transaction-page-column-heading__blue'>
                        オールインワンプラン
                        <div className='info-small-heading'>
                          アルバイト・正社員・特定技能
                        </div>
                        <div className='info-box-container'>
                          <div className='info-box'>任意入力欄</div>
                          <div className='info-box'>PR機能</div>
                        </div>
                      </div>
                      <div>
                        <button
                          className='transaction-page-button btn2'
                          onClick={()=> this.selectJobType('PT2')}
                        >
                          アルバイトの求人を作成する
                        </button>
                      </div>
                    </div>
                    <div>
                      <button
                        className='transaction-page-button btn3'
                        onClick={()=> this.selectJobType('FT')}
                      >
                        正社員の求人を作成する
                      </button>
                    </div>
                    <div>
                      <button
                        className='transaction-page-button btn4'
                        onClick={()=> this.selectJobType('SSV')}
                      >
                        特定技能の求人を作成する
                      </button>
                    </div>
                  </div>
                  <div className='transaction-page-column'>
                    <div className='transaction-page-column-heading transaction-page-column-heading__red'>
                      アルバイト採用
                      <br/>
                      限定プラン
                    </div>
                    <div>
                      <button
                        className='transaction-page-button btn1'
                        onClick={()=> this.selectJobType('PT1')}
                      >
                        アルバイトの求人を作成する
                      </button>
                    </div>
                    <div className='transaction-page-column-text'>
                      ※任意入力欄やPR機能はありません。在留資格・日本語力など人材へのこだわりがある方は、オールインワンプランを選択ください。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='billing-heading-text2'>
              プラン早見表
            </div>
            <img
              src={SelectNewPlanImage4}
              className='billing-plan-image'
              alt="close"
            />
          </div>}
          <div className='image-5-container'>
            <img
                src={SelectNewPlanImage5}
                alt="close"
            />
          </div>
          <div className='billing-body-container-2'>
            <img
                src={SelectNewPlanImage}
                alt="close"
              />
            {/* <div className='billing-heading-text3'>
              プラン詳細
            </div> */}
            <div>
              <img src={JobTypeImage} alt='Job Type Info' style={{width: '100%', marginTop: 0}} />
            </div>
            <div
            className='select-plan-container-2 container-2-mobile'
            style={{
              paddingBottom: '20px',
              textAlign: 'justify',
              fontSize: '14px'
            }}
          >
            ♢<span style={{fontWeight: '500'}}>掲載について</span>
            <div style={{marginLeft: '13px'}}>※１記事に「募集職種＋雇用形態＋勤務地」が各一つずつ掲載できます。</div>
            <div style={{marginLeft: '26px'}}>例１）正社員とアルバイトを募集する場合は2記事となります。</div>
            <div style={{marginLeft: '26px'}}>例２）同じ勤務地でも、皿洗い・ウエイターなど２職種の募集する場合は2記事となります。</div>
            <div style={{marginLeft: '13px'}}>※プラン間の記事の流用はできません。</div>
            {/*<div style={{marginLeft: '13px'}}>※2つのプランの同時掲載はできませんが、掲載中に別プランの購入はできます。</div>*/}

            <br/>
            <br/>
            ♢<span style={{fontWeight: '500'}}>お支払い方法</span>
            <div style={{marginLeft: '13px'}}>お支払はクレジットカードまたは請求書払いがあります。</div>
            <div style={{marginLeft: '13px'}}>クレジットカード払なら即掲載開始できます。請求書払いの場合はご利用にあたり審査が必要です。</div>
            <br/>
            <br/>
            ♢<span style={{fontWeight: '500'}}>その他の注意事項</span>
            <br/>
            <div style={{marginLeft: '13px'}}>
              <div>※料金は一括払いとなります。</div>
              <div>※購入したプランの変更やキャンセルはできません。</div>
              <div>※WORK JAPANは求人サイトであり、適性や能力等を保証するものではありません。</div>
              <div style={{marginLeft: '13px'}}>また、紹介フィーをいただく事はありません。</div>
            </div>
          </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    language: state.language,
    token: state.user.token
  }
};

SelectJobType = connect(mapStateToProps)(SelectJobType);
export default withStyles(useStyles)(SelectJobType)
