import React, { Component } from 'react';
import InnerHeader from '../components/InnerHeader.js'
import SelectDropdown from '../components/SelectDropdown.js'
import InputBox from '../components/InputBox.js'
import { connect } from 'react-redux';
import { addMemberAPI, fetchMemberDetailsAPI } from '../helperFunctions/dataServices.js'
import { ENV_TYPE } from '../../constants';
import ErrorModal from '../components/ErrorModal'
import { completedJoyride } from '../../actions';
import DashboardTranslations from '../../DashboardTranslations.js';
import SuccessModal from '../components/SuccessModal'
import Joyride from 'react-joyride';
import ScaleLoader from 'react-spinners/ScaleLoader';
import MemberCard from '../components/MemberCard'
import ReactGA from 'react-ga';
import './styles/teamLanding.scss';
import {getErrorMessage} from "../../messages/errors";
import { Snackbar } from '@material-ui/core';
import checkCircle from '../../images/svg/check-circle.svg';
const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class TeamLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      type: "view",
      email: "",
      selectedRole: "",
      submitted: false,
      showSuccess: false,
      showError: false,
      mainKey: 0,
      loading: false,
      error: "",
      steps: [
        {
          target: '.team-landing-container',
          content: '※記事作成ページから、登録ユーザーを面接担当者に指定する事ができます',
          title: '複数メンバーで記事や面接管理ができます。',
          disableBeacon: true,
        },
      ]
    }
  }

  componentDidMount() {
    // localStorage.setItem("joyride", JSON.stringify([]));
    if (ENV_TYPE === "PROD") {
      this.initializeReactGA()
    }
    if (this.props.joyride.completed.indexOf("teamLanding") > -1) {
      this.setState({steps: []});
    }
    this.fetchMemberDetails()
  }

  initializeReactGA() {
    ReactGA.initialize('UA-104174852-1');
    ReactGA.pageview('/dashboard/teams');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.joyride !== this.props.joyride) {
      this.checkForJoyride()
    }
  }

  checkForJoyride() {
    if (this.props.joyride.completed.indexOf("sidebar") > -1) {
      if (this.props.joyride.completed.indexOf("teamLanding") > -1) {
        this.setState({steps: []});
      }
      else {
        this.restartJoyride()
      }
    }
  }

  backArrowOnClick() {
    this.props.history.goBack();
  }

  openAddMember() {
    this.setState({type: "add"})
  }

  handleChange = event => {
    var value = event.target.value
    this.setState({ [event.target.name]: value });
  }

  fetchMemberDetails() {
    this.setState({loading: true})
    fetchMemberDetailsAPI().then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        var modified = response.data.members.concat(response.data.invitees)
        this.setState({members: modified, loading: false})
      }
      else {
        this.setState({loading: false})
      }
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  addMember() {
    var payload = {}
    payload.emails = [this.state.email]
    var isAdminValue = false
    if (this.state.selectedRole === "admin") {
      isAdminValue = true
    }
    payload.is_admin = isAdminValue
    this.setState({submitted: true})
    if (emailFormat.test(this.state.email) && this.state.selectedRole !== "") {
      addMemberAPI(payload).then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          if (response.data && response.data.failed_emails.length === 0) {
            this.setState({submitted: false, email: '', loading: false, type: "view", showSuccess: true}, () => this.fetchMemberDetails())
          } else {
            this.setState({loading: false, showError: true, error: getErrorMessage('team_page_add_member_email_already_present_message')});
          }
        }
        else {
          this.setState({loading: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""})
        }
      })
      .catch((e) => {
        this.setState({loading: false, showError: true})
      })
    }
  }

  handleSuccess() {
    this.setState({showSuccess: false})
  }

  handleErrorClose = () => {
    this.setState({showError: false, error: ''})
  }

  handleJoyrideCallback(data) {
    if (data.status === "finished" && data.lifecycle === "complete") {
      var joyride = this.props.joyride.completed
      if (joyride.indexOf("teamLanding") > -1) {
        // joyride = joyride.filter(item => item !== "teamLanding")
      }
      else {
        joyride[joyride.length] = "teamLanding"
      }
      this.props.dispatch(completedJoyride(joyride));
    }
  }

  restartJoyride() {
    var steps= [
      {
        target: '.team-landing-container',
        content: '※記事作成ページから、登録ユーザーを面接担当者に指定する事ができます',
        title: '複数メンバーで記事や面接管理ができます。',
        disableBeacon: true,
      },
    ]
    this.setState({
      mainKey: this.state.mainKey + 1,
      steps: steps
    });
  }


  render() {
    var { members, type, email, selectedRole, submitted,
      error, showError, showSuccess, loading,
      steps, mainKey,
    } = this.state

    var { user } = this.props

    var roleOptions = [
      {value: "admin", label: "管理者"},
      {value: "member", label: "一般"}
    ]
    var currentMember = []
    members.forEach((member) => {
      if (member.email === user.email) {
        currentMember = member
      }
    })

    var sortedMemberList = []
    if (currentMember) {
      sortedMemberList.push(currentMember)
    }
    for (var i = 0; i < members.length; i++) {
      if (members[i].email !== user.email) {
        sortedMemberList.push(members[i])
      }
    }

    var memberCards
    if (sortedMemberList.length > 0) {
      memberCards = sortedMemberList.map((member, index) => <MemberCard removeSuccess={() => {
        this.setState({removeSuccess: true})
      }} fetchMemberDetails={this.fetchMemberDetails.bind(this)} isAdmin={currentMember.is_admin} key={index} index={index} memberDetails={member} />)
    }

    return (
      <div className="team-landing-container">
        <InnerHeader
          primary={true}
          history={this.props.history}
          headerText={DashboardTranslations.team_page_heading}
          backArrowOnClick={this.backArrowOnClick.bind(this)}
          buttonOneText={DashboardTranslations.team_page_add_member}
          buttonOneClick={this.openAddMember.bind(this)}
        />
        <ScaleLoader sizeUnit={"px"} height={50}
          width={4} color={'#007AFF'} loading={loading}
        />
        {currentMember.is_admin && <div className="add-member-button" onClick={() => this.setState({type: "add"})}>
          {DashboardTranslations.team_page_add_member}
        </div>}
        <Joyride
          autoStart={true}
          run={true}
          key={mainKey}
          steps={steps}
          continuous={true}
          disableCloseOnEsc={true}
          disableOverlayClose={true}
          locale={{ back: '前へ', close: '閉じる', last: '閉じる', next: '次へ', skip: 'スキップ' }}
          showStepsProgress={true}
          callback={this.handleJoyrideCallback.bind(this)}
        />
        {!loading &&
          <div className="guide-container" style={{textAlign: "center"}}>
            <button className="btn btn-primary" onClick={() => this.restartJoyride()}>使い方</button>
          </div>
        }
        {!loading && memberCards}
        {type === "add" &&
          <div className="add-team-member-form-background">
            <div className="add-team-member-form-container">
            <InputBox
              errorMessage="メールアドレスを正しく入力してください。"
              error={submitted === true && !emailFormat.test(email) ? true : false}
              label={DashboardTranslations.team_page_email_label}
              name="email"
              value={email}
              handleChange={this.handleChange.bind(this)}
            />
            <SelectDropdown
              label={DashboardTranslations.team_page_role_label}
              errorMessage="`ユーザーの役割を正しく選択してください`"
              error={submitted === true && selectedRole === ""}
              name="selectedRole"
              value={selectedRole}
              options={roleOptions}
              handleChange={this.handleChange.bind(this)}
            />
            <div className="submit-container">
              <div className="btn btn-primary" onClick={() => this.addMember()}>{DashboardTranslations.team_page_add_member_submit_button}</div>
              <div className="btn btn-secondary" onClick={() => this.setState({type: "view"})}>{DashboardTranslations.team_page_add_member_cancel_button}</div>
            </div>
          </div>
          </div>
        }
        <SuccessModal successMessage={DashboardTranslations.team_page_add_member_success_message} handleClose={this.handleSuccess.bind(this)} showSuccess={showSuccess}/>
        <ErrorModal error={error} handleClose={this.handleErrorClose.bind(this)} showError={showError}/>
        <Snackbar 
            open={this.state.removeSuccess} 
            className='snackbar-details-icon'
            autoHideDuration={3000} 
            onClose={() => {
              this.setState({removeSuccess: false})
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            message={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={checkCircle}
                  alt="icon"
                  style={{ marginRight: 8, width: 40, height: 40 }}
                />
                ユーザーを削除しました
              </span>
            }
          />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token,
        joyride: state.joyride,
        user: state.user
    }
};

TeamLanding = connect(mapStateToProps)(TeamLanding);

export default TeamLanding;
