import React, { Component } from 'react';
import { connect } from 'react-redux';
import daysArray from '../helperFunctions/daysArray.js'
import Carousel from "./Carousel";
import { jobs, initializeJob, deleteImage } from '../helperFunctions/dataServices.js'
import DashboardTranslations from '../../DashboardTranslations.js';
import { getErrorMessage } from '../../messages/errors';
import FontAwesomeIcon from 'react-fontawesome'
import HelpIcon from '../../images/help-icon.png'
import InputBox from '../components/InputBox.js'
import MultipleSelect from "../components/MultipleSelect";
import SalesMerit from '../components/SalesMerit'
import SalesMeritsIconList from '../helperFunctions/SalesMeritsIconList'
import ErrorModal from '../components/ErrorModal'
import SelectDropdown from '../components/SelectDropdown.js'
import MultiSelectDropdown from '../components/MultiSelectDropdown.js'
import ImageUploader from 'react-images-upload';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox'
import Shift from '../components/NewShift.js'
import './styles/addJobs.scss';
import {getTooltipMessage} from "../../messages/tooltips";
import {disabledFields, mandatoryFields} from "./constants";
import Modal from "@material-ui/core/Modal";
import Radio from "@material-ui/core/Radio";
import down from "../../images/svg/down-new.svg";
import down_disabled from "../../images/svg/down-new-disabled.svg";
const Compress = require('compressorjs')


class AddJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobType: null,
      error: false,
      position: '',
      work: "",
      salaryMax: "",
      salaryMin: "",
      monthlySalaryMax: "",
      monthlySalaryMin: "",
      annualSalaryMax: "",
      annualSalaryMin: "",
      trainingSalaryMax: "",
      bonusPaidPerYear: "",
      raisePerYear: "",
      overtimeHoursPerMonth: "",
      minHourlySalary: 750,
      trainingSalaryMin: "",
      trainingDuration: "",
      startTime: "09:00",
      endTime: "09:30",
      days: "",
      addShiftsSubmitted: false,
      jobsOptions: {},
      remoteWork: '',
      holidays: [],
      company: null,
      companyNameJp: '',
      companyNameEn: '',
      companyIndustryType: '',
      companyEmployee: null,
      nationalities: [],
      website: '',

      jobDetail: '',
      tempDetail: "貴社の業務内容や仕事内容、応募者に期待することや伝えたいポイントを記載してください。Google翻訳で多言語に翻訳するため、意訳される可能性がある事をご了承ください。\n\n        ------------記入例------------\n＜仕事の内容＞\n・ベッドメイキングや室内の清掃\n・キッチンでの調理作業や洗い場のサポート\n・倉庫での搬入作業・運び入れ\n・配送作業\n・チェックインの受付\n\n日本語でトイレの場所を案内することがあります。\n\n＜勤務時間＞\n◯◯：◯◯〜\n(1日 ◯時間）\n\n※詳しくは、下に記載されている勤務時間帯を確認してください\n・深夜のみの勤務もOK!\n・土日のみの勤務もOK!\n・ダブルワークも歓迎しています！\n\n＜勤務地＞\n◯◯駅 (駅から徒歩◯分)\n\n＜働く環境＞\n・外国人観光客に人気の海鮮居酒屋です。\n・外国人（ベトナムやネパール人など）も働いており、教育も整っています。経験がない方も、安心してください。\n\n＜給料＞\n給料例: 1ヶ月で◯◯万円\n・給料の前払いにも対応しています。\n\n＜面接に向けて＞\n・面接日時の確認で、電話が来ることがあります\nTEL: ◯◯-◯◯◯◯-◯◯◯◯\n・面接の際は在留資格カードを持参ください。\n・オンライン面接を希望される方はご連絡ください。\n\n【歓迎】\n・長時間シフトに入れる、配偶者・定住者・永住者の方\n・英語以外の言語ができる方",
      preferredExperienceYear: null,
      preferredQualification: null,
      preferredSkills: [],
      preferredJPLevel: '',
      preferredEnLevel: '',
      acceptanceCertificates: [],
      willSupportVisaChange: null,
      socialInsuranceProvided: '',

      merits_groups: {},
      salesMeritsSimpleObj: {},
      certificatesSimpleObj: {},
      ssvIndustriesSimpleObj: {},
      membersSimpleObj: {},
      jobSubcategoriesSimpleObj: {},
      selectedMerits: [],
      inCharge: "",
      submitted: false,
      shifts: [],
      selectedDays: [],
      // preferredExperience: [],
      selectedCopyFrom: '',
      jobs: [],
      leaveErrorSpaceSalary: false,
      leaveErrorSpace: false,
      transportationCovered: '',
      // customSalesMerit: "",
      preferredVisaTypes: [],
      preferredCertificates: [],
      industries: [],
      customPreferredCertificates: "",
      preferredExperiences: [],
      customPreferredExperiences: "",
      preferredQualityOrVolume: "",
      preferredGender: "",
      pictures: [],
      picturesURL: [],
      defaultWorkImages: [],
      preferredNationalities: [],
      nationality: "",
      noImagesAdded: true,
      jpLevel: "",
      type: "add",
      showError: false,
      errorMessage: "",

      textAreaModal: false,
      multipleSelectionModal: false,
      multipleSelection: {
        field: '',
        items: [],
        selectedItems: []
      },
      multipleSelectionExpandDiv: [],
      errorMapping: {},
      buttonClicked: false
    }

    this.onDrop = this.onDrop.bind(this);
  }

  checkError(callback) {
    let errorMapping = {}
    let {
      preferredJPLevel, willSupportVisaChange,
      companyNameJp, companyNameEn, companyIndustryType, companyEmployee,
      website, monthlySalaryMin, monthlySalaryMax, annualSalaryMin,
      annualSalaryMax
    } = this.state

    if(this.state.buttonClicked || this.props.edit || callback) {
      const defaultErrorMessage = 'この項目は必須です'

      if(preferredJPLevel === '' && !this.isDisabled('preferredJPLevel')) {
        errorMapping['preferredJPLevel'] = defaultErrorMessage
      }

      if(willSupportVisaChange === null && !this.isDisabled('willSupportVisaChange')) {
        errorMapping['willSupportVisaChange'] = defaultErrorMessage
      }

      if(companyNameJp === '' && this.state.jobType !== 'PT1' ) {
        errorMapping['companyNameJp'] = defaultErrorMessage
      }

      if(companyNameEn === '' && this.state.jobType !== 'PT1') {
        errorMapping['companyNameEn'] =  defaultErrorMessage
      }

      if(companyIndustryType === ''&& !this.isDisabled('companyIndustryType')) {
        errorMapping['companyIndustryType'] = defaultErrorMessage
      }

      if((companyEmployee === '' || companyEmployee === null) && !this.isDisabled('companyEmployee')) {
        errorMapping['companyEmployee'] = defaultErrorMessage
      }

      if(!(this.websiteValid(website)) && !this.isDisabled('website')) {
        errorMapping['website'] = 'URL形式が正しくありません'
      }

      if(['FT', 'SSV'].includes(this.state.jobType)) {

        if(monthlySalaryMin === '' && monthlySalaryMax !== '') {
          errorMapping.monthlySalaryMin = defaultErrorMessage
        } else if(monthlySalaryMin !== '' && monthlySalaryMax === '') {
          errorMapping.monthlySalaryMax = defaultErrorMessage
        }

        if(annualSalaryMin === '' && annualSalaryMax !== '') {
          errorMapping.annualSalaryMin = defaultErrorMessage
        } else if(annualSalaryMin !== '' && annualSalaryMax === '') {
          errorMapping.annualSalaryMax = defaultErrorMessage
        }

        const monthlySalaryEmpty = monthlySalaryMin === '' && monthlySalaryMax === ''
        const annualSalaryEmpty = annualSalaryMin === '' && annualSalaryMin === ''

        if(monthlySalaryEmpty && annualSalaryEmpty) {
          errorMapping.monthlySalaryMax = defaultErrorMessage
          errorMapping.monthlySalaryMin = defaultErrorMessage
          errorMapping.annualSalaryMax = defaultErrorMessage
          errorMapping.annualSalaryMin = defaultErrorMessage
        }
      }
    }

    if(callback) {
      this.setState({errorMapping: errorMapping}, callback)
    } else {
      this.setState({errorMapping: errorMapping}, callback)
    }
    if(Object.values(errorMapping).length) {
      this.props.setNextButtonClickable(false);
    }
  }

  websiteValid() {
    if(this.state.website === '' || this.state.website === null) return true
    let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(this.state.website);
  }

  componentDidMount() {
    var type = this.state.type
    if (this.props.history.location.pathname.indexOf("dashboard/edit") >= 0) {
      type = "edit"
    }
    else if (this.props.history.location.pathname.indexOf("dashboard/view") >= 0) {
      type = "view"
    }
    else if (this.props.history.location.pathname.indexOf("dashboard/copy") >= 0) {
      type = "copy"
    }
    this.setState({type: type})
    this.setJobType()

    if (Object.keys(this.props.jobDetails).length > 0) {
      var workShifts = this.props.jobDetails.work_timing
      var shifts = []
      var id = 0
      for(var key in workShifts) {
          var split = key.split('-')
          shifts.push({id: id, timeFrom: split[0], timeTo: split[1], days: workShifts[key]})
          id += 1
        }
      var jobDetails = this.props.jobDetails
      var selectedMerits= jobDetails.sales_merits
      var work = jobDetails.subcategory ? jobDetails.subcategory.id : ""
      var inCharge = jobDetails.interview_in_charge && jobDetails.interview_in_charge.id
      var salaryMin = jobDetails.hourly_salary_min
      var salaryMax = jobDetails.hourly_salary_max
      var trainingDuration = jobDetails.training_days ? jobDetails.training_days : ""
      var trainingSalaryMin = jobDetails.training_hourly_salary_min ? jobDetails.training_hourly_salary_min : ""
      var trainingSalaryMax = jobDetails.training_hourly_salary_max ? jobDetails.training_hourly_salary_max : ""
      // var customSalesMerit = jobDetails.custom_sales_merit ? jobDetails.custom_sales_merit.join(",") : ""
      var transportationCovered = jobDetails.is_transportation_covered_for_interview ? jobDetails.is_transportation_covered_for_interview : false
      var preferredVisaTypes = jobDetails.preferred_visa_types ? jobDetails.preferred_visa_types : []
      var preferredCertificates = jobDetails.preferred_certificates ? jobDetails.preferred_certificates : []
      let ssvIndustry = jobDetails.ssv_industry ? jobDetails.ssv_industry : ''
      var customPreferredCertificates = jobDetails.preferred_other_certificates ? jobDetails.preferred_other_certificates.join(",") : ""
      var preferredExperiences = jobDetails.preferred_job_experiences ? jobDetails.preferred_job_experiences : []
      var customPreferredExperiences = jobDetails.preferred_other_job_experiences ? jobDetails.preferred_other_job_experiences.join(",") : ""
      var preferredQualityOrVolume = jobDetails.quality_or_volume ? jobDetails.quality_or_volume : "Q"
      var preferredGender = jobDetails.preferred_gender ? jobDetails.preferred_gender : "M"
      var pictures = jobDetails.work_images ? jobDetails.work_images : []
      var nationalitiesAndJPLevel = jobDetails.preferred_nationalities ? jobDetails.preferred_nationalities : []
      var preferredNationalities = []
      if (nationalitiesAndJPLevel.length > 0) {
        preferredNationalities = nationalitiesAndJPLevel.map((nation, id) => {
          var single = {id: id, nationality: nation.nationality, jpLevel: nation.preferred_jp_level}
          return single;
        })
      }

      var picturesURL = pictures.map(pic => pic.url)

      this.setState({selectedMerits: selectedMerits, work: work, inCharge: inCharge,
        salaryMin: salaryMin, salaryMax: salaryMax, trainingDuration: trainingDuration, trainingSalaryMin: trainingSalaryMin,
        trainingSalaryMax: trainingSalaryMax, shifts: shifts, transportationCovered: transportationCovered,
        preferredVisaTypes: preferredVisaTypes, preferredCertificates: preferredCertificates, ssvIndustry: ssvIndustry, customPreferredCertificates: customPreferredCertificates,
        preferredExperiences: preferredExperiences, customPreferredExperiences: customPreferredExperiences, preferredQualityOrVolume: preferredQualityOrVolume,
        preferredGender: preferredGender, preferredNationalities: preferredNationalities, pictures: pictures, defaultWorkImages: pictures, picturesURL: picturesURL,
        preferredExperienceYear: jobDetails.preferred_experience_year,
        preferredQualification: jobDetails.preferred_qualification,
        preferredSkills: jobDetails.preferred_skills
      }, () => this.sendPreviewJobData())
    }
    this.initializeJobs()
    this.getJobs()

    if(Object.keys(this.props.jobDetails).length) {
      this.setState({loading: true})
      this.updateState()
    }

  }

  expandCollapse(fieldName) {
    let divs = [...this.state.multipleSelectionExpandDiv]
    if (divs.includes(fieldName)) {
      divs.splice(divs.indexOf(fieldName), 1)
    } else {
      divs.push(fieldName)
    }
    this.setState({multipleSelectionExpandDiv: divs})
  }

  handleSelectedItem(event, item) {
    let selectedItems = this.state.multipleSelection.selectedItems
    if(this.state.multipleSelection.selectedItems.includes(item.id)) {
      selectedItems.splice(selectedItems.indexOf(item.id), 1)
    } else {
      selectedItems.push(item.id)
    }
    console.log({selectedItems, item})
    this.setState(
      {
        multipleSelection: {
          ...this.state.multipleSelection,
          selectedItems: selectedItems
        }})
  }

  updateSelectedItems() {
    const fieldName = this.state.multipleSelection.field
    if(fieldName === 'skill') {
      this.setState({preferredSkills: this.state.multipleSelection.selectedItems})
    }

    if(fieldName === 'certificate') {
      this.setState({preferredCertificates: this.state.multipleSelection.selectedItems})
    }

    if(fieldName === 'visa') {
      this.setState({preferredVisaTypes: this.state.multipleSelection.selectedItems})
    }

    if(fieldName === 'ssv-industry') {
      this.setState({ssvIndustry: this.state.multipleSelection.selectedItems})
    }

    if(fieldName === 'industry') {
      this.setState({industries: this.state.multipleSelection.selectedItems})
    }

    if(fieldName === 'nationality') {
      this.setState({nationalities: this.state.multipleSelection.selectedItems})
    }

    this.setState(
      {
        multipleSelectionModal: false,
        multipleSelection: {field: '', items: [], selectedItems: []}
      })
  }

  openMultipleSelectionModal(fieldName) {
    const data = {...this.state.multipleSelection, field: fieldName}
    if(fieldName === 'skill') {
      data.items = JSON.parse(JSON.stringify(this.state.jobsOptions.skills))
      data.selectedItems = JSON.parse(JSON.stringify(this.state.preferredSkills))
    }

    if(fieldName === 'certificate') {
      data.items = JSON.parse(JSON.stringify(this.state.jobsOptions.certificates))
      data.selectedItems = JSON.parse(JSON.stringify(this.state.preferredCertificates))
    }

    if(fieldName === 'visa') {
      data.items = JSON.parse(JSON.stringify(this.state.jobsOptions.visa_types))
      data.selectedItems = JSON.parse(JSON.stringify(this.state.preferredVisaTypes))
    }

    if(fieldName === 'ssv-industry') {
      data.items = JSON.parse(JSON.stringify(this.state.jobsOptions.ssv_industries))
      let selectedData = []
      if(this.state.ssvIndustry) {
        if(typeof this.state.ssvIndustry === 'number') {
          selectedData = [this.state.ssvIndustry]
        } else {
          selectedData = this.state.ssvIndustry
        }
      }
      data.selectedItems = JSON.parse(JSON.stringify(selectedData))
    }

    if(fieldName === 'industry') {
      data.items = JSON.parse(JSON.stringify(this.state.jobsOptions.industries))
      data.selectedItems = JSON.parse(JSON.stringify(this.state.industries))
    }

    if(fieldName === 'nationality') {
      data.items = JSON.parse(JSON.stringify(this.state.jobsOptions.default_nationality_choices))
      data.selectedItems = JSON.parse(JSON.stringify(this.state.nationalities))
    }
    console.log({data})

    this.setState({multipleSelectionModal: true, multipleSelection: data})
  }

  handleAcceptanceItem(item) {
    let acceptanceCertificates = [...this.state.acceptanceCertificates]
    if(acceptanceCertificates.includes(item.value)) {
      acceptanceCertificates.splice(acceptanceCertificates.indexOf(item.value), 1)
    } else {
      acceptanceCertificates.push(item.value)
    }
    this.setState({acceptanceCertificates: acceptanceCertificates})
  }

  handleHolidayItem(item) {
    let holidays = [...this.state.holidays]
    if(holidays.includes(item.value)) {
      holidays.splice(holidays.indexOf(item.value), 1)
    } else {
      holidays.push(item.value)
    }
    this.setState({holidays: holidays})
  }

  deleteOldImages(id, imageIndex) {
    this.setState({loading: true})
    if (this.props.isDraftView && this.props.draftJobId) {
      this.deleteDraftImage(id, imageIndex)
    } else {
      this.deleteImage(this.props.jobDetails.id, id, imageIndex)
    }
  }

  deleteDraftImage(id, imageIndex) {
    let defaultWorkImagesUpdated = this.state.defaultWorkImages;
    defaultWorkImagesUpdated.splice(imageIndex, 1)
    this.setState({defaultWorkImages: defaultWorkImagesUpdated})
  }

  deleteImage(jobId, pic, imageIndex) {
    var payload ={
      image_id: parseInt(pic)
    }
    deleteImage(jobId, payload).then((response) => {
    	if (response && response.status >= 200 && response.status < 300) {
        response.json().then(json => {
          var newPicturesURL = this.state.picturesURL.filter((pic, index) => index !== imageIndex)
          var defaultImage = ""
          if (newPicturesURL.length === 1) {
            this.state.defaultSubCategoryList.map((sub) => {
              if (parseInt(sub.id) === parseInt(this.state.work)) {
                defaultImage = sub.default_images[0]
              }
            })
            if (this.props.type === "add" || this.props.type === "copy") {
              var defaultImageArray = []
              defaultImageArray.push(defaultImage.url)
              this.setState({pictures: defaultImage, defaultWorkImages: defaultImage, picturesURL: defaultImageArray, noImagesAdded: true}, () => this.sendPreviewJobData())
            }
          }
          else {

          }
          this.setState({loading: false, defaultWorkImages: json.work_images, picturesURL: newPicturesURL}, () => this.sendPreviewJobData())
        });
    	}
    	else {
    		this.setState({loading: false})
    	}
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  selectDay(day) {
    var newSelectedDay = []
    if (this.state.selectedDays.indexOf(day) > -1) {
      this.state.selectedDays.forEach((d) => {
        if (d !== day) {
          newSelectedDay.push(d)
        }
      })
    }
    else {
      newSelectedDay = this.state.selectedDays
      newSelectedDay.push(day)
    }
    this.setState({selectedDays: newSelectedDay})
  }

  selectedMerits(id) {
		var selectedMerits = this.state.selectedMerits

		var temp = []
		if (this.state.selectedMerits.indexOf(id) >= 0) {
			var arrayIndex = 0
			this.state.selectedMerits.forEach((index) => {
				if (parseInt(index, 10) !== parseInt(id, 10)) {
					temp[arrayIndex] = index
					arrayIndex = arrayIndex + 1
				}
			})
		}
		else {
			temp = selectedMerits
			var length = temp.length
			temp[length] = id
		}
        this.setState({ selectedMerits: temp}, this.sendPreviewJobData);
	}

  initializeJobs() {
    this.setState({loading: true})
    initializeJob().then((response) => {
    	if (response && response.status >= 200 && response.status < 300) {
        var jobsOptions = response.data
        var meritCategories = jobsOptions.merit_categories
        for(var category in meritCategories) {
          meritCategories[category] = meritCategories[category].map(item => {
            item.iconName = SalesMeritsIconList[item.icon_name] ? SalesMeritsIconList[item.icon_name] : '';
            return item
          })
        }
        var subcategoryList = []
        // var categoriesList = []
        if (jobsOptions.job_categories) {
          jobsOptions.job_categories.forEach(category => {
            // var main = <option key={category.name} style={{borderBottom: '1px solid gray', borderTop : '1px solid gray'}} disabled value={category.name}>{category.name}</option>
            // categoriesList.push(main)
            category.subcategories.forEach(subcategory => {
              subcategoryList.push(subcategory)
            })
          })
        }

        jobsOptions.merit_categories = meritCategories
        jobsOptions.default_nationality_choices = jobsOptions.nationality_choices

        var nationalityIds = this.state.preferredNationalities.map(item => parseInt(item.nationality))
        var nationalityChoices = jobsOptions.default_nationality_choices
        nationalityChoices = nationalityChoices.filter(nation => {
          if (nationalityIds.indexOf(nation.id) < 0) {
            return nation;
          }
        })
        jobsOptions.nationality_choices = nationalityChoices;


        let salesMeritsSimpleObj = {};
        for (const property in meritCategories) {
          for (const meritObj of meritCategories[property]) {
            salesMeritsSimpleObj[meritObj.id] = meritObj.name;
          }
        }

        let certificatesSimpleObj = {};
        for (const certificateObj of jobsOptions.certificates) {
          certificatesSimpleObj[certificateObj.id] = certificateObj.name;
        }

        let ssvIndustriesSimpleObj = {};
        for (const ssvIndustryObj of jobsOptions.ssv_industries) {
          ssvIndustriesSimpleObj[ssvIndustryObj.id] = ssvIndustryObj.name;
        }

        let membersSimpleObj = {};
        for (let member of jobsOptions.members) {
          membersSimpleObj[member.id] = member.display_name;
        }

        let jobSubcategoriesSimpleObj = {};
          for (const categoryObj of jobsOptions.job_categories) {
            for (const subcategoryObj of categoryObj.subcategories) {
              jobSubcategoriesSimpleObj[subcategoryObj.id] = [subcategoryObj.name, categoryObj.name];
            }
          }

        this.setState({
          jobsOptions: jobsOptions,
          loading: false,
          merits_groups: meritCategories,
          defaultSubCategoryList: subcategoryList,
          salesMeritsSimpleObj: salesMeritsSimpleObj,
          certificatesSimpleObj: certificatesSimpleObj,
          ssvIndustriesSimpleObj: ssvIndustriesSimpleObj,
          jobSubcategoriesSimpleObj: jobSubcategoriesSimpleObj,
          membersSimpleObj: membersSimpleObj
        })
    	}
    	else {
    		this.setState({loading: false})
    	}
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  handleChange = event => {
    const value = event.target.value;
    const name = event.target.name;

    if (name === "trainingSalaryMax" || name === "trainingSalaryMin" || name === "trainingDuration") {
      if (name === "trainingDuration") {
        if (value.length < 3) {
          this.setState({ [event.target.name]: value }, () => this.sendPreviewJobData())
        }
      }
      else {
        this.setState({ [event.target.name]: value }, () => this.checkTrainingSalaryValidation());
      }
    }
    else {
      let defaultImage = "";
      if (name === "work") {
        this.state.defaultSubCategoryList.map((sub) => {
          if (parseInt(sub.id) === parseInt(value)) {
            defaultImage = sub.default_images[0]
          }
        });
        if ((this.props.type === "add" || this.props.type === "copy") && !this.props.isDraftView) {
          var defaultImageArray = [];
          defaultImageArray.push(defaultImage.url);

          if (this.state.noImagesAdded) {
            this.setState({pictures: defaultImage, defaultWorkImages: [defaultImage], picturesURL: defaultImageArray, noImagesAdded: true}, () => this.sendPreviewJobData())
          } else {
            this.setState({defaultWorkImages: [defaultImage]}, () => this.sendPreviewJobData())
          }
        }
      }

      this.setState({ [name]: value }, () => {this.checkSalaryValidation(); this.sendData(false)});
    }
  };

  checkSalaryValidation() {
    var { salaryMax, salaryMin, minHourlySalary } = this.state
    if (this.props.submitted) {
      if (
        (parseInt(salaryMax) >= parseInt(salaryMin) && parseInt(salaryMin) >= parseInt(minHourlySalary) && parseInt(salaryMax) <= 25000) &&
        !this.isDisabled('hourlySalaryMin') && !this.isDisabled('hourlySalaryMax')
      ) {
        this.setState({leaveErrorSpaceSalary: false}, () => this.sendPreviewJobData())
      }
      else {
        this.setState({leaveErrorSpaceSalary: true}, () => this.sendPreviewJobData())
      }
    }
    this.sendPreviewJobData()
  }

  checkTrainingSalaryValidation() {
    var { trainingSalaryMin, trainingSalaryMax } = this.state
    if (this.props.submitted) {
      if (parseInt(trainingSalaryMin) <= parseInt(trainingSalaryMax) && parseInt(trainingSalaryMin) >= 750 && parseInt(trainingSalaryMax) <= 25000) {
        this.setState({leaveErrorSpaceTrainingSalary: false}, () => this.sendPreviewJobData())
      }
      else {
        this.setState({leaveErrorSpaceTrainingSalary: true}, () => this.sendPreviewJobData())
      }
    }
    this.sendPreviewJobData()
  }

  addShiftsButton() {
    var { startTime, endTime, selectedDays } = this.state
    this.setState({addShiftsSubmitted: true})
    if (startTime !== "" && endTime !== "" && selectedDays.length>0) {
      var id = this.state.shifts.length
      var shifts = {id: id, timeFrom: startTime, timeTo: endTime, days: selectedDays}
      var newShifts = this.state.shifts
      newShifts.push(shifts)
      this.setState({shifts: newShifts, startTime: "09:00", endTime: "09:30", selectedDays: [], addShiftsSubmitted: false}, () => this.sendPreviewJobData())
    }
  }

  getJobs(type) {
    this.setState({loading: true})
    jobs().then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        response.data.sort(this.sortJobs)
        this.setState({jobs: response.data, loading: false})
      }
      else {
        this.setState({loading: false})
      }
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  handleErrorClose() {
    this.setState({showError: false, errorMessage: ""})
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.submitted !== this.props.submitted) {
      this.checkTrainingSalaryValidation()
      this.checkSalaryValidation()
    }
    if (prevProps.draftDataCollection !== this.props.draftDataCollection && this.props.currentStage === 3) {
      this.sendData(true, true)
    }
    if (prevProps.selectedPrefecture !== this.props.selectedPrefecture) {
      var minHourlySalary = 800
      if (this.state.jobsOptions.prefectures) {
        var minHourlySalary = this.state.jobsOptions.prefectures.filter(pref => parseInt(pref.id) === parseInt(this.props.selectedPrefecture))
        minHourlySalary = minHourlySalary[0].minimum_wage
      }
      this.setState({minHourlySalary: minHourlySalary}, () => this.checkSalaryValidation())
    }

    if(prevProps.collectDataCount !== this.props.collectDataCount && this.props.collectDataCount !== 0) {
      this.checkError((e) => this.sendData(true))
    }

    if (prevProps.jobDetails !== this.props.jobDetails) {
      this.setJobType()
      this.updateState()

      var workShifts = this.props.jobDetails.work_timing
      var shifts = []
      var id = 0
      for(var key in workShifts) {
          var split = key.split('-')
          shifts.push({id: id, timeFrom: split[0], timeTo: split[1], days: workShifts[key]})
          id += 1
        }
      var jobDetails = this.props.jobDetails
      var selectedMerits= jobDetails.sales_merits
      var work = jobDetails.subcategory ? jobDetails.subcategory.id : ""
      var inCharge = jobDetails.interview_in_charge.id
      var salaryMin = jobDetails.hourly_salary_min
      var salaryMax = jobDetails.hourly_salary_max
      var trainingDuration = jobDetails.training_days ? jobDetails.training_days : ""
      var trainingSalaryMin = jobDetails.training_hourly_salary_min ? jobDetails.training_hourly_salary_min : ""
      var trainingSalaryMax = jobDetails.training_hourly_salary_max ? jobDetails.training_hourly_salary_max : ""
      var preferredCertificates = jobDetails.preferred_certificates ? jobDetails.preferred_certificates : [];
      let ssvIndustry = jobDetails.ssv_industry ? jobDetails.ssv_industry : '';

      let pictures = [];
      if (this.state.type !== 'copy') {
        pictures = jobDetails.work_images ? jobDetails.work_images : [];
      }

      this.setState({
        selectedMerits: selectedMerits,
        work: work,
        inCharge: inCharge,
        salaryMin: salaryMin,
        salaryMax: salaryMax,
        trainingDuration: trainingDuration,
        trainingSalaryMin: trainingSalaryMin,
        trainingSalaryMax: trainingSalaryMax,
        shifts: shifts,
        preferredCertificates: preferredCertificates,
        ssvIndustry: ssvIndustry,
        defaultWorkImages: pictures
        })
    }

    if (this.props.isDraftView && prevProps.isDraftView !== this.props.isDraftView && this.props.draftJobDetails['step3']) {
      this.setJobType()
      const step3DraftJobDetails = this.props.draftJobDetails['step3'];
      this.updateStateFromDraft(step3DraftJobDetails)

      var workShifts = step3DraftJobDetails.work_timing
      var shifts = []
      var id = 0
      for(var key in workShifts) {
          var split = key.split('-')
          shifts.push({id: id, timeFrom: split[0], timeTo: split[1], days: workShifts[key]})
          id += 1
      }
      var jobDetails = step3DraftJobDetails
      var selectedMerits= jobDetails.sales_merits
      var work = jobDetails.subcategory ? jobDetails.subcategory.id : ""
      var inCharge = jobDetails.interview_in_charge ? jobDetails.interview_in_charge.id : ""
      var salaryMin = jobDetails.hourly_salary_min
      var salaryMax = jobDetails.hourly_salary_max
      var trainingDuration = jobDetails.training_days ? jobDetails.training_days : ""
      var trainingSalaryMin = jobDetails.training_hourly_salary_min ? jobDetails.training_hourly_salary_min : ""
      var trainingSalaryMax = jobDetails.training_hourly_salary_max ? jobDetails.training_hourly_salary_max : ""
      var preferredCertificates = jobDetails.preferred_certificates ? jobDetails.preferred_certificates : [];
      let ssvIndustry = jobDetails.ssv_industry ? jobDetails.ssv_industry : '';
      let selectedCopyFrom = jobDetails.selectedCopyFrom ? jobDetails.selectedCopyFrom : '';

      let pictures = [];
      if (this.state.type !== 'copy') {
        pictures = jobDetails.work_images ? jobDetails.work_images : [];
      }

      // console.log('Showing Images:: ', pictures)

      this.setState({
        selectedMerits: selectedMerits,
        work: work,
        inCharge: inCharge,
        salaryMin: salaryMin,
        salaryMax: salaryMax,
        selectedCopyFrom: selectedCopyFrom,
        trainingDuration: trainingDuration,
        trainingSalaryMin: trainingSalaryMin,
        trainingSalaryMax: trainingSalaryMax,
        shifts: shifts,
        preferredCertificates: preferredCertificates,
        ssvIndustry: ssvIndustry,
        defaultWorkImages: pictures
      })
    }
  }

  async getBase64Image(url) {
    const data = await fetch(url);
    const blob = await data.blob();
    // return new Promise((resolve) => {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(blob);
    //   reader.onloadend = () => {
    //     const base64data = reader.result;
    //     resolve(base64data);
    //   }
    // });
    return blob;
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.collectData!==prevState.collectData){
      return {collectData: nextProps.collectData}
    }
    else return null;
    if(nextProps.jobDetails!==prevState.jobDetails){
      return {jobDetails: nextProps.jobDetails}
    }
    else return null;
  }

  selectCopyFrom(event) {
    var id = event.target.value
    var copyFrom = this.state.selectedCopyFrom
    if (copyFrom === id) {
      copyFrom = ''
    }
    else {
      copyFrom = id
    }

    var selectedMerits = this.state.jobs.filter(data => parseInt(data.id) === parseInt(id))
    if (selectedMerits) {
      selectedMerits = selectedMerits[0].sales_merits
      this.setState({selectedMerits: selectedMerits}, () => this.sendPreviewJobData())
    }
    this.setState({selectedCopyFrom: copyFrom}, () => this.sendPreviewJobData())
  }

  sendPreviewJobData() {
    var { customPreferredCertificates, preferredCertificates, selectedMerits, shifts, picturesURL, work,
      salaryMin, salaryMax, trainingSalaryMin, inCharge, trainingSalaryMax, trainingDuration, transportationCovered,
      jobSubcategoriesSimpleObj, ssvIndustry } = this.state;

    var payload = {}
    payload.preferred_other_certificates = customPreferredCertificates
    payload.sales_merits = selectedMerits;

    let sales_merits_str = '';
    for (const element of selectedMerits) {
      if (!sales_merits_str) {
        sales_merits_str = this.state.salesMeritsSimpleObj[element];
      } else {
        sales_merits_str += `, ${this.state.salesMeritsSimpleObj[element]}`;
      }
    }

    let preferredCertificatesStr = '';
    for (const element of preferredCertificates) {
      if (!preferredCertificatesStr) {
        preferredCertificatesStr = this.state.certificatesSimpleObj[element];
      } else {
        preferredCertificatesStr += `, ${this.state.certificatesSimpleObj[element]}`;
      }
    }

    let ssvIndustriesStr = '';
    if(ssvIndustry) {
      ssvIndustriesStr = this.state.ssvIndustriesSimpleObj[ssvIndustry];
    }

    payload.inChargeName = this.state.membersSimpleObj[inCharge];
    payload.preferredCertificatesStr = preferredCertificatesStr;
    payload.ssvIndustriesStr = ssvIndustriesStr;
    payload.jobSubcategoryName = jobSubcategoriesSimpleObj[work] ? jobSubcategoriesSimpleObj[work][0] : '';
    payload.jobCategoryName = jobSubcategoriesSimpleObj[work] ? jobSubcategoriesSimpleObj[work][1] : '';
    payload.sales_merits_str = sales_merits_str;
    payload.shifts = shifts
    payload.hourly_salary_min = this.getValueOrNull(parseInt(salaryMin))
    payload.hourly_salary_max = this.getValueOrNull(parseInt(salaryMax))
    payload.training_hourly_salary_min = this.getValueOrNull(parseInt(trainingSalaryMin))
    payload.training_hourly_salary_max = this.getValueOrNull(parseInt(trainingSalaryMax))
    payload.training_days = this.getValueOrNull(parseInt(trainingDuration))

    let defaultWorkImages_ = [];
    for (const element of this.state.defaultWorkImages) {
      defaultWorkImages_.push(element.url);
    }

    payload.imageList = [...new Set(picturesURL.concat(defaultWorkImages_))];
    payload.transportationCovered = transportationCovered;
    this.props.previewJobsLocation(payload, "job")
  }


  async sendData(shouldCallVerifyJob, sendFromDraft=false) {

    var { selectedMerits, shifts, work, inCharge, salaryMin, salaryMax, trainingSalaryMax,
      trainingDuration, trainingSalaryMin, minHourlySalary, transportationCovered,
      preferredNationalities, preferredVisaTypes, preferredCertificates, customPreferredCertificates, ssvIndustry,
      preferredExperiences, customPreferredExperiences, preferredQualityOrVolume, preferredGender,
    } = this.state;

    if(!this.state.buttonClicked) {
      this.checkError()
    }

    if(shouldCallVerifyJob) this.setState({buttonClicked: true})

    var trainingDurationValid = false
    if (trainingDuration === "" || trainingDuration === null) {
      trainingDurationValid = true
    }
    else if (parseInt(trainingDuration) > 0) {
      trainingDurationValid = true
    }
    else {
      trainingDurationValid = false
    }

    var trainingSalaryMinError = false
    var trainingSalaryMaxError = false
    if ((trainingSalaryMax === "" && trainingSalaryMin === "") || parseInt(trainingSalaryMin) >= 750) {
      trainingSalaryMinError = false
    }
    else {
      trainingSalaryMinError = true
    }
    if ((trainingSalaryMax === "" && trainingSalaryMin === "") || (parseInt(trainingSalaryMax) <= 25000 && parseInt(trainingSalaryMin) <= parseInt(trainingSalaryMax))) {
      trainingSalaryMaxError = false
    }
    else {
      trainingSalaryMaxError = true
    }

    var shiftsFormat = {}
    shifts.forEach(shift => {
      var key=shift.timeFrom+'-'+shift.timeTo
      shiftsFormat[key] = shift.days
    })
    // var customMerits = customSalesMerit.split(",")
    var customCertificates = customPreferredCertificates.split(",")
    var customExperiences = customPreferredExperiences !== "" ? customPreferredExperiences.split(",") : []

    var preferred_nationalities = preferredNationalities.map((nation) => {
      var single = {nationality: nation.nationality, preferred_jp_level: parseInt(nation.jpLevel)}
      return single;
    })

    var pictureLabels = ["image1", "image2", "image3", "image4",  "image5"]
    var picturesPayload;

    if (this.state.pictures.length > 0) {
      picturesPayload = new FormData()
      this.state.pictures.map((pic, id) => {
        picturesPayload.append(pictureLabels[id], pic)
      })
    }
    else if (this.state.pictures.id) {
      picturesPayload = {}
      picturesPayload.image_ids = [this.state.pictures.id]
    }

    if (this.props.isDraftView) {
      // console.log('Adding More Image::0::: -> ', Array.isArray(this.state.defaultWorkImages), this.state.defaultWorkImages.length > 0)
      if (Array.isArray(this.state.defaultWorkImages) &&
       this.state.defaultWorkImages.length > 0) {
        // console.log('Adding More Image::1::: -> ', picturesPayload instanceof FormData)
        if (!(picturesPayload instanceof FormData)) {
          picturesPayload = new FormData();
        }
        const getAllImagesLength = Array.from(picturesPayload.keys()).length;
        // console.log('Adding More Image::2::: -> ', getAllImagesLength, getAllImagesLength < 5)
        if (getAllImagesLength < 5) {
          const updatedLengthPictureLabels = pictureLabels.splice(getAllImagesLength)
          // console.log('Adding More Image::3::: -> ', updatedLengthPictureLabels)
          await Promise.all(this.state.defaultWorkImages.map(async (item, index) => {
            const url = await this.getBase64Image(item.url);
            if (index < updatedLengthPictureLabels.length && !picturesPayload.has(updatedLengthPictureLabels[index])) {
              picturesPayload.append(updatedLengthPictureLabels[index], url)
            }
          }))
          // console.log('Adding More Image::4::: -> ', Array.from(picturesPayload.keys()), Array.from(picturesPayload.keys()).length)
        }
      }
    }
    // console.log('New Form Data:: ', picturesPayload);

    if (!sendFromDraft) {
      this.setState({submitted: true}, this.checkError)
    }

    let salaryValid = parseInt(salaryMax) <= 25000 && parseInt(salaryMin) >= parseInt(minHourlySalary) && parseInt(salaryMax) >= parseInt(salaryMin)
    if(this.isDisabled('hourlySalaryMin') && this.isDisabled('hourlySalaryMax')) {
      salaryValid = true
    }

    if (work !== "" && inCharge !== "" && shifts.length > 0) {
      if (salaryValid && trainingDurationValid && !trainingSalaryMinError && !trainingSalaryMaxError && transportationCovered !== "" &&
        !Object.values(this.state.errorMapping).length
      ) {
        var payload = {};
        payload.interview_in_charge = parseInt(inCharge)
        payload.hourly_salary_min = this.getValueOrNull(parseInt(salaryMin))
        payload.hourly_salary_max = this.getValueOrNull(parseInt(salaryMax))
        payload.sales_merits = selectedMerits
        // payload.preferred_experiences = preferredExperience
        payload.work_timing = shiftsFormat
        if (trainingDuration !== "") {
          payload.training_days = trainingDuration
        }
        if (trainingSalaryMin !== "" && parseInt(trainingSalaryMin) >= 750) {
          payload.training_hourly_salary_min = parseInt(trainingSalaryMin)
        }
        if (trainingSalaryMax !== "" && parseInt(trainingSalaryMax) <= 25000 && (parseInt(trainingSalaryMin) <= parseInt(trainingSalaryMax))) {
          payload.training_hourly_salary_max = parseInt(trainingSalaryMax)
        }

        payload.is_transportation_covered_for_interview = transportationCovered === 'true'
        // if (customSalesMerit !== "") {
        //   payload.custom_sales_merit = customMerits
        // }
        payload.preferred_nationalities = preferred_nationalities
        payload.preferred_visa_types = preferredVisaTypes
        if (customPreferredCertificates !== "") {
          payload.preferred_other_certificates = customCertificates
        }

        payload.preferred_certificates = preferredCertificates 
        payload.ssv_industry = ssvIndustry
        payload.preferred_job_experiences = preferredExperiences
        payload.preferred_other_job_experiences = customExperiences
        payload.preferred_gender = preferredGender === "" ? "NA" : preferredGender
        payload.quality_or_volume = preferredQualityOrVolume

        if (!this.props.edit || this.props.type === "copy") {
          payload.subcategory = parseInt(work)
        }

        this.updatePayload(payload)
        this.props.updateAdJobData(payload, this.state.jobsOptions)

        if (shouldCallVerifyJob) this.props.verifyJob(payload, picturesPayload, picturesPayload instanceof FormData ?  Array.from(picturesPayload.keys()).length : this.state.pictures.length, sendFromDraft);
        this.props.setNextButtonClickable(true);
      }
      else {
        if (sendFromDraft) {
          const data = this.getUpdatedDraftData(
            inCharge, salaryMin, salaryMax,
            selectedMerits, shiftsFormat, trainingDuration,
            trainingSalaryMin, trainingSalaryMax, transportationCovered,
            preferred_nationalities, preferredVisaTypes, customCertificates,
            preferredCertificates, ssvIndustry, preferredExperiences, 
            customExperiences, preferredGender, preferredQualityOrVolume, work,
            customPreferredCertificates
            );
          this.props.verifyJob(data, picturesPayload, picturesPayload instanceof FormData ?  Array.from(picturesPayload.keys()).length : this.state.pictures.length, sendFromDraft);
          return;
        }
        this.props.setNextButtonClickable(false);
        this.props.scrollToMyRef();
      }
    }
    else {

      if (sendFromDraft) {
        const data = this.getUpdatedDraftData(
          inCharge, salaryMin, salaryMax,
          selectedMerits, shiftsFormat, trainingDuration,
          trainingSalaryMin, trainingSalaryMax, transportationCovered,
          preferred_nationalities, preferredVisaTypes, customCertificates,
          preferredCertificates, ssvIndustry, preferredExperiences, 
          customExperiences, preferredGender, preferredQualityOrVolume, work,
          customPreferredCertificates
          );
        this.props.verifyJob(data, picturesPayload, picturesPayload instanceof FormData ?  Array.from(picturesPayload.keys()).length : this.state.pictures.length, sendFromDraft);
        return;
      }

      this.props.setNextButtonClickable(false);
      this.props.scrollToMyRef();
    }
  }

  getUpdatedDraftData(
    inCharge, salaryMin, salaryMax,
    selectedMerits, shiftsFormat, trainingDuration,
    trainingSalaryMin, trainingSalaryMax, transportationCovered,
    preferred_nationalities, preferredVisaTypes, customCertificates,
    preferredCertificates, ssvIndustry, preferredExperiences, 
    customExperiences, preferredGender, preferredQualityOrVolume, work,
    customPreferredCertificates
  ) {
    var payload = {};
    payload.interview_in_charge = parseInt(inCharge)
    payload.hourly_salary_min = this.getValueOrNull(parseInt(salaryMin))
    payload.hourly_salary_max = this.getValueOrNull(parseInt(salaryMax))
    payload.sales_merits = selectedMerits
    // payload.preferred_experiences = preferredExperience
    payload.work_timing = shiftsFormat
    if (trainingDuration !== "") {
      payload.training_days = trainingDuration
    }
    if (trainingSalaryMin !== "" && parseInt(trainingSalaryMin) >= 750) {
      payload.training_hourly_salary_min = parseInt(trainingSalaryMin)
    }
    if (trainingSalaryMax !== "" && parseInt(trainingSalaryMax) <= 25000 && (parseInt(trainingSalaryMin) <= parseInt(trainingSalaryMax))) {
      payload.training_hourly_salary_max = parseInt(trainingSalaryMax)
    }

    payload.is_transportation_covered_for_interview = transportationCovered === 'true'
    // if (customSalesMerit !== "") {
    //   payload.custom_sales_merit = customMerits
    // }
    payload.preferred_nationalities = preferred_nationalities
    payload.preferred_visa_types = preferredVisaTypes
    if (customPreferredCertificates !== "") {
      payload.preferred_other_certificates = customCertificates
    }

    payload.preferred_certificates = preferredCertificates
    payload.ssv_industry = ssvIndustry
    payload.preferred_job_experiences = preferredExperiences
    payload.preferred_other_job_experiences = customExperiences
    payload.preferred_gender = preferredGender === "" ? "NA" : preferredGender
    payload.quality_or_volume = preferredQualityOrVolume

    if (!this.props.edit || this.props.type === "copy") {
      payload.subcategory = parseInt(work)
    }
    payload.selectedCopyFrom = this.state.selectedCopyFrom;

    this.updatePayload(payload)
    return payload;
  }

  updateState() {
    if(!Object.keys(this.state.jobsOptions).length) {
      this.initializeJobs()
      setTimeout(() => {
        this.updateState()
      }, 500)
    } else {
      let payload = this.props.jobDetails
      let data = {}
      data.jobType = payload.job_type
      data.position = payload.position
      data.jobDetail = payload.description
      data.preferredExperienceYear = payload.preferred_experience_year
      data.preferredQualification = payload.preferred_qualification
      data.preferredSkills = payload.preferred_skills
      data.preferredJPLevel = payload.preferred_jp_level
      data.preferredEnLevel = payload.preferred_en_level
      data.preferredVisaTypes = payload.preferred_visa_types
      data.acceptanceCertificates = payload.who_can_apply
      data.willSupportVisaChange = payload.will_support_visa_change
      data.monthlySalaryMin = payload.monthly_salary_min ? payload.monthly_salary_min : ''
      data.monthlySalaryMax = payload.monthly_salary_max ? payload.monthly_salary_max : ''
      data.annualSalaryMin = payload.annual_salary_min ? payload.annual_salary_min : ''
      data.annualSalaryMax = payload.annual_salary_max ? payload.annual_salary_max : ''
      data.bonusPaidPerYear = payload.bonus_paid_per_year ? payload.bonus_paid_per_year : ''
      data.trainingDuration = payload.training_days ? payload.training_days : ''
      data.raisePerYear = payload.raise_paid_per_year ? payload.raise_paid_per_year : ''
      data.overtimeHoursPerMonth = payload.over_time_hours_per_month ? payload.over_time_hours_per_month : ''
      data.remoteWork = payload.remote_work ? payload.remote_work : ''
      data.holidays = payload.holidays
      data.socialInsuranceProvided = payload.is_social_insurance_provided ? payload.is_social_insurance_provided : ''
      data.transportationCovered = payload.is_transportation_covered_for_interview

      if(payload.company_info && payload.company_info.id) {
        this.setState(data, () => this.updateCompany(payload.company_info.id))
      } else {
        this.setState(data)
      }
      this.setState({loading: false})
    }
  }

  updateStateFromDraft(draftData) {
    if(!Object.keys(this.state.jobsOptions).length) {
      this.initializeJobs()
      setTimeout(() => {
        this.updateStateFromDraft(draftData)
      }, 500)
    } else {
      let payload = draftData
      let data = {}
      data.position = payload.position
      data.jobDetail = payload.description
      data.preferredExperienceYear = payload.preferred_experience_year
      data.preferredQualification = payload.preferred_qualification
      data.preferredSkills = payload.preferred_skills
      data.preferredJPLevel = payload.preferred_jp_level
      data.preferredEnLevel = payload.preferred_en_level
      data.preferredVisaTypes = payload.preferred_visa_types
      data.acceptanceCertificates = payload.who_can_apply
      data.willSupportVisaChange = payload.will_support_visa_change
      data.monthlySalaryMin = payload.monthly_salary_min ? payload.monthly_salary_min : ''
      data.monthlySalaryMax = payload.monthly_salary_max ? payload.monthly_salary_max : ''
      data.annualSalaryMin = payload.annual_salary_min ? payload.annual_salary_min : ''
      data.annualSalaryMax = payload.annual_salary_max ? payload.annual_salary_max : ''
      data.bonusPaidPerYear = payload.bonus_paid_per_year ? payload.bonus_paid_per_year : ''
      data.trainingDuration = payload.training_days ? payload.training_days : ''
      data.raisePerYear = payload.raise_paid_per_year ? payload.raise_paid_per_year : ''
      data.overtimeHoursPerMonth = payload.over_time_hours_per_month ? payload.over_time_hours_per_month : ''
      data.remoteWork = payload.remote_work ? payload.remote_work : ''
      data.holidays = payload.holidays
      data.socialInsuranceProvided = payload.is_social_insurance_provided ? payload.is_social_insurance_provided : ''
      data.transportationCovered = payload.is_transportation_covered_for_interview
      // console.log('Da:: ', data, draftData);

      if(payload.company_info) {
        this.setState(data, () => this.updateDraftCompany(payload.company_info))
      } else {
        this.setState(data)
      }
      this.setState({loading: false})
    }
  }

  updatePayload(payload) {

    let {
      position, jobType, preferredExperienceYear,
      preferredQualification, preferredSkills, preferredJPLevel,
      preferredEnLevel, acceptanceCertificates, willSupportVisaChange,
      monthlySalaryMin, monthlySalaryMax, annualSalaryMax, annualSalaryMin,
      bonusPaidPerYear,
      raisePerYear, overtimeHoursPerMonth, trainingDuration, remoteWork,
      holidays, socialInsuranceProvided, companyNameJp, companyNameEn, companyIndustryType,
      companyEmployee, nationalities, website, company, jobDetail
    } = this.state

    payload.job_type = jobType
    payload.position = this.getValueOrNull(parseInt(position))
    payload.description = this.getValueOrNull(jobDetail)
    payload.preferred_experience_year = this.getValueOrNull(parseInt(preferredExperienceYear))
    payload.preferred_qualification = this.getValueOrNull(parseInt(preferredQualification))
    payload.preferred_skills = preferredSkills
    payload.preferred_jp_level = this.getValueOrNull(parseInt(preferredJPLevel))
    payload.preferred_en_level = this.getValueOrNull(parseInt(preferredEnLevel))
    payload.who_can_apply = acceptanceCertificates
    payload.will_support_visa_change = this.getValueOrNull(willSupportVisaChange)
    payload.monthly_salary_min = this.getValueOrNull(parseInt(monthlySalaryMin))
    payload.monthly_salary_max = this.getValueOrNull(parseInt(monthlySalaryMax))
    payload.annual_salary_min = this.getValueOrNull(parseInt(annualSalaryMin))
    payload.annual_salary_max = this.getValueOrNull(parseInt(annualSalaryMax))
    payload.bonus_paid_per_year = this.getValueOrNull(parseInt(bonusPaidPerYear))
    payload.training_days = this.getValueOrNull(parseInt(trainingDuration))
    payload.raise_paid_per_year = this.getValueOrNull(parseInt(raisePerYear))
    payload.over_time_hours_per_month = this.getValueOrNull(parseInt(overtimeHoursPerMonth))
    payload.remote_work = this.getValueOrNull(remoteWork)
    payload.holidays = holidays

    if(socialInsuranceProvided === '') {
      payload.is_social_insurance_provided = null
    } else {
      payload.is_social_insurance_provided = socialInsuranceProvided === 'true'
    }

    let companyInfo = {}
    companyInfo.name_jp = this.getValueOrNull(companyNameJp)
    companyInfo.name_en = this.getValueOrNull(companyNameEn)
    companyInfo.industry = this.getValueOrNull(parseInt(companyIndustryType))
    companyInfo.num_of_employees = this.getValueOrNull(parseInt(companyEmployee))
    companyInfo.nationalities_hired = nationalities
    companyInfo.website = this.getValueOrNull(website)

    if(this.getValueOrNull(company)) {
      payload.company_info_id = this.getValueOrNull(parseInt(company))
    } else {
      let tempData = {...companyInfo, nationalities_hired: nationalities.length ? nationalities: null}
      if(Object.values(tempData).some((data) => Boolean(data))) {
        payload.company_info = companyInfo
      } else {
        payload.company_info = null
      }
    }

  }

  getValueOrNull(value) {
    return ((typeof value === 'string' && value === '') || (typeof value === 'number' && isNaN(value))) ? null : value
  }

  updateCompany(companyId) {
    let companyData = {
      company: null,
      companyNameJp: '',
      companyNameEn: '',
      companyIndustryType: '',
      companyEmployee: '',
      nationalities: [],
      website: '',
    }
    if(companyId) {
      let companyInfo = this.state.jobsOptions.company_infos.find(info => String(info.id) === String(companyId))
      companyData.company = companyId
      companyData.companyNameJp = companyInfo.name_jp
      companyData.companyNameEn = companyInfo.name_en
      companyData.companyIndustryType = companyInfo.industry ? companyInfo.industry : ''
      companyData.companyEmployee = companyInfo.num_of_employees
      companyData.nationalities = companyInfo.nationalities_hired
      companyData.website = companyInfo.website ? companyInfo.website : ''
    }
    this.setState(this.setState(companyData, this.checkError))
  }

  updateDraftCompany(companyInfo) {
    let companyData = {
      company: null,
      companyNameJp: '',
      companyNameEn: '',
      companyIndustryType: '',
      companyEmployee: '',
      nationalities: [],
      website: '',
    }
    if(companyInfo) {
      if (companyInfo.id) {
        companyData.company = companyInfo.id;
      }
      if (companyInfo.name_jp) {
        companyData.companyNameJp = companyInfo.name_jp
      }
      if (companyInfo.name_en) {
        companyData.companyNameEn = companyInfo.name_en
      }
      if (companyInfo.industry_id) {
        companyData.companyIndustryType = companyInfo.industry_id
      } else {
        companyData.companyIndustryType = companyInfo.industry ? companyInfo.industry : ''
      }
      if (companyInfo.num_of_employees) {
        companyData.companyEmployee = companyInfo.num_of_employees
      }
      if (companyInfo.nationalities_hired) {
        companyData.nationalities = companyInfo.nationalities_hired
      }
      companyData.website = companyInfo.website ? companyInfo.website : ''
    }
    this.setState(this.setState(companyData))
  }

  deleteShift(id) {
    var shifts = this.state.shifts.filter(sh => sh.id !== id)
    shifts = shifts.map((sh, index) => {
      var shift = {id: index, timeFrom: sh.timeFrom, timeTo: sh.timeTo, days: sh.days}
      return shift;
    })
    // this.setState({shifts: shifts})
    this.setState({shifts: shifts}, () => this.sendData(false))
  }

  compressImage(file) {
    return new Promise((resolve, reject) => {
        new Compress(file, {
            convertSize: 1, // the max size in MB, defaults to 2MB
            quality: .75, // the quality of the image, max is 1,
            maxWidth: 1920, // the max width of the output image, defaults to 1920px
            maxHeight: 1920, // the max height of the output image, defaults to 1920px
            resize: 'contain', // defaults to true, set false if you do not want to resize the image width and height
            success: (result) => {
                resolve(new File([result], file.name, {type: result.type}))
            },
            error: (error) => reject(error)
        })
    });
}

  onDrop(picture, picturesURL) {
    if (picture.length > 5) {
      this.setState({showError: true, errorMessage: getErrorMessage('add_job_image_upload_exceed_maximum_number_error_message')}, () => this.sendPreviewJobData())
    }
    else {
      const compressPromises = [];
        for (const file of picture) {
            compressPromises.push(this.compressImage(file));
        }
        Promise.all(compressPromises).then((data) => {
          // returns an array of compressed images
          var oldPicturesUrl = [];
          this.setState({
            pictures: data,
            noImagesAdded: picturesURL.length === 0 ? true : false
          }, () => this.sendPreviewJobData());
          if (picturesURL.length !== 0) {
            this.setState({picturesURL: oldPicturesUrl ? oldPicturesUrl.concat(picturesURL) : picturesURL}, () => this.sendPreviewJobData())
          }
        })
      .catch((e) => {
        console.log(e);
      })
      if (picturesURL.length === 0 && this.state.work !== "") {
        var defaultImageArray = []
        defaultImageArray.push(this.state.defaultWorkImages[0].url)
        this.setState({noImagesAdded: true, picturesURL: defaultImageArray}, () => this.sendPreviewJobData())
      }
    }
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
    bstr = window.atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    var blob = new Blob([u8arr], {type: 'image/png'});
    blob.lastModifiedDate = new Date();
    return blob
    // return new Blob([u8arr], filename, {type:"image/png"});
  }


  addNationalityAndJPLevel() {
    var { nationality, jpLevel, preferredNationalities, jobsOptions } = this.state
    if (preferredNationalities.length === 5) {
      this.setState({showError: true, errorMessage: getErrorMessage('add_job_nationality_exceed_maximum_error_message')})
    }
    else {
      if (nationality !== "" && jpLevel !== "") {
        var duplicate = false
        preferredNationalities.map((item, index) => {
          if (parseInt(item.nationality) === parseInt(nationality) && parseInt(item.jpLevel) === parseInt(jpLevel)) {
            duplicate = true
          }
        })
        if (duplicate) {
          this.setState({showError: true, errorMessage: "You have already added this preferrence"})
        }
        else {
          var id = this.state.preferredNationalities.length
          var single = {id: id, nationality: nationality, jpLevel: jpLevel}
          var newNationalities = this.state.preferredNationalities
          newNationalities.push(single)
          var nationalityChoices = jobsOptions.nationality_choices
          nationalityChoices = nationalityChoices.filter(item => parseInt(item.id) !== parseInt(nationality))
          var newJobOptions = jobsOptions
          newJobOptions.nationality_choices = nationalityChoices
          this.setState({preferredNationalities: newNationalities, nationality: "", jpLevel: "", jobsOptions: newJobOptions})
        }
      }
    }
  }

  deleteNationalitiesAndJPLevel(id) {
    var single = this.state.preferredNationalities.filter(nationalityAndJpLevel => parseInt(nationalityAndJpLevel.id) !== parseInt(id))
    single = single.map((nationalityAndJpLevel, index) => {
      var singleNationality = {id: index, nationality: nationalityAndJpLevel.nationality, jpLevel: nationalityAndJpLevel.jpLevel}
      return singleNationality;
    })
    var nationalityIds = single.map(item => parseInt(item.nationality))
    var nationalityChoices = this.state.jobsOptions.default_nationality_choices
    nationalityChoices = nationalityChoices.filter(nation => {
      if (nationalityIds.indexOf(nation.id) < 0) {
        return nation;
      }
    })
    var newJobOptions = this.state.jobsOptions
    newJobOptions.nationality_choices = nationalityChoices
    this.setState({preferredNationalities: single, jobsOptions: newJobOptions})
  }

  setJobType(callback) {
    let jobType = ''
    if(this.props.history.location.state && this.props.history.location.state.job_type) {
      jobType = this.props.history.location.state.job_type
    }
    if(this.props.jobDetails.job_type) {
      jobType = this.props.jobDetails.job_type
    }

    if(jobType) {
      if(callback) {
        this.setState({jobType: jobType}, callback)
      } else {
        this.setState({jobType: jobType})
      }
    }
  }

  isDisabled (fieldName) {
    if(!this.state.jobType) {
      this.setJobType(this.isDisabled)
    } else {
      return disabledFields[this.state.jobType].includes(fieldName)
    }
  }

  isMandatory (fieldName) {
    if(!this.state.jobType) {
      this.setJobType(this.isMandatory)
    } else {
      if(mandatoryFields[this.state.jobType].includes(fieldName)) {
        return <span style={{marginLeft: '5px', color: 'red'}}>*</span>
      } return ''
    }
  }


  render() {
    var {  work, salaryMin, salaryMax, trainingDuration, leaveErrorSpaceSalary, leaveErrorSpaceTrainingSalary,
          trainingSalaryMin, trainingSalaryMax, jobs, minHourlySalary, startTime, jobsOptions, inCharge, selectedCopyFrom,
          endTime, transportationCovered, preferredCertificates, type, showError, errorMessage, ssvIndustry,
          jobType
        } = this.state

    var { submitted } = this.props

    var shifts = this.state.shifts.map((sh, index) => {
      var japaneseDays = sh.days.map(englishDay => {
        var key = daysArray.englishDays.indexOf(englishDay)

        return this.props.language === "En" ? daysArray.days[key].id : daysArray.days[key].name;
      })
      var daySring = japaneseDays.join(", ")
      return (
        <div className="work-hour-single" key={index}>
          <div className="work-hour-left">
            {daySring}
          </div>
          <div className="work-hour-right">
            <span>{sh.timeFrom} - {sh.timeTo}</span>
            <FontAwesomeIcon onClick={(this.props.type === 'view') ? undefined : () => this.deleteShift(sh.id)}
              style={{fontSize: 20, color: "#e06e6f", margin: "0px 5px", cursor: "pointer"}}
              name="times-circle" className="icon"/>
          </div>
        </div>
      )
    })

    var copyFromList = <div />
    copyFromList = jobs.map(copyFrom => {
      var copyFromList = {value: copyFrom.id, label: copyFrom.web_id}
      return copyFromList
    })

    const getMembers = () => {
      const members = [...jobsOptions.members]
      if(this.props.jobDetails.interview_in_charge) {
        members.push(this.props.jobDetails.interview_in_charge)
        return Array.from(new Set(members.map(a => a.id)))
        .map(id => {
          return members.find(a => a.id === id)
        })
      }
      return members
    }

    var inchargeOptions = [{value: "", label: "選択"}];
    if (jobsOptions.prefectures) {
      inchargeOptions = getMembers().map((option, id) => {
        var inCharge = {value: option.id, label: option.display_name};
        return inCharge
      })
    }

    var companyOptions = [];
    if (jobsOptions.company_infos) {
      companyOptions = jobsOptions.company_infos.map((option, id) => {
        var company = {value: option.id, label: option.name_jp};
        return company
      })
    }

    var qualificationOptions = [
      {value: "2", label: "高校"},
      {value: "3", label: "専門学校"},
      {value: "4", label: "大学"},
      {value: "5", label: "修士課程"},
      {value: "6", label: "博士課程"},
    ]

    var jpLevelOptions = [
      {value: "0", label: "日本語力不問"},
      {value: "1", label: "日常会話"},
      {value: "2", label: "流暢"},
      {value: "3", label: "ビジネスレベル"}
    ]

    var engLevelOptions = [
      {value: "0", label: "日常会話"},
      {value: "1", label: "流暢"},
      {value: "2", label: "ビジネスレベル"}
    ]

    var acceptanceOptions = [];
    if (jobsOptions.who_can_apply) {
      acceptanceOptions = jobsOptions.who_can_apply.map((option, id) => {
        var acceptance = {value: option.id, label: option.name};
        return acceptance
      })
    }
    var ssvOptions = [];
    if(jobsOptions.ssv_industries) {
      ssvOptions = jobsOptions.ssv_industries.map((option) => {
        return {value: option.id, label: option.name}
      })
    }

    var visaChangeOptions = [
      {value: 'Yes', label: "可"},
      {value: 'No', label: "不可"},
      {value: 'Conditional', label: "パフォーマンスによる"},
    ]

    var socialInsuranceOptions = [
      {value: true, label: "有り"},
      {value: false, label: "無し"},
    ]


    var transportationCoveredOptions = [
      {value: true, label: DashboardTranslations.add_job_page_transportation_covered_yes},
      {value: false, label: DashboardTranslations.add_job_page_transportation_covered_no}
    ]

    var carousel = []
    var carouselIds = []
    if (this.props.type !== "add" && this.state.defaultWorkImages.length > 0) {
      carousel = this.state.defaultWorkImages.map((pic, index) => {
        carouselIds.push(pic.id)
        return pic.url
      })
    }
    if (this.props.isDraftView && this.state.defaultWorkImages.length > 0) {
      carousel = this.state.defaultWorkImages.map((pic, index) => {
        carouselIds.push(pic.id)
        return pic.url
      })
    }

    var preferredCertificatesOptions = [{value: "", label: "選択"}]
    if (jobsOptions.certificates) {
      preferredCertificatesOptions = jobsOptions.certificates.map((option, id) => {
        return {value: option.id, label: option.name};
      })
    }

    let ssvIndustriesOptions = [{value: "", label: "選択"}]
    if (jobsOptions.ssv_industries) {
      ssvIndustriesOptions = jobsOptions.ssv_industries.map((option, id) => {
        return {value: option.id, label: option.name};
      })
    }

    let industriesOptions = [{value: "", label: "選択"}]
    if (jobsOptions.industries) {
      industriesOptions = jobsOptions.industries.map((option, id) => {
        return {value: option.id, label: option.name};
      })
    }

    let holidayOptions = []
    if (jobsOptions.holidays) {
      holidayOptions = jobsOptions.holidays.map((option, id) => {
        return {value: option.id, label: option.name};
      })
    }

    var meritsLeft = []
    var meritsRight = []
    if (Object.keys(this.state.merits_groups).length > 0) {
      var id = 0
      if (this.state.merits_groups['外国人にやさしい'].length > 0) {
        var header = <div className="sales-header">外国人にやさしい</div>
        var merit = this.state.merits_groups["外国人にやさしい"].map((item, index) => {
					var selected = false
					if (this.state.selectedMerits.indexOf(item.id) >= 0) {
						selected = true
					}
					return (
						<SalesMerit disabled={this.props.type === 'view'} item={item} selected={selected} selectedMerits={this.selectedMerits.bind(this)} key={item.id}/>
					)
				})
        var fullMerit =
          <div key="外国人にやさしい" className="single-merit-category-container">
            <div className="sales-merits-header">
              {header}
            </div>
            {merit}
          </div>
          meritsLeft.push(fullMerit)
          id++
      }
      for(var key in this.state.merits_groups) {
        if (key !== "外国人にやさしい") {
          var header = <div className="sales-header">{key}</div>
          var merit = this.state.merits_groups[key].map((item, index) => {
  					var selected = false
  					if (this.state.selectedMerits.indexOf(item.id) >= 0) {
  						selected = true
  					}
  					return (
  						<SalesMerit disabled={this.props.type === 'view'} item={item} selected={selected} selectedMerits={this.selectedMerits.bind(this)} key={item.id}/>
  					)
  				})
          var fullMerit =
            <div key={key} className="single-merit-category-container">
              <div className="sales-merits-header">
                {header}
              </div>
              {merit}
            </div>
          if (id > 3) {
            meritsRight.push(fullMerit)
          }
          else {
            meritsLeft.push(fullMerit)
          }
          id++;
        }
      }
    }

    // var subcategoryList = []

    var categoriesList = []
    if (jobsOptions.job_categories) {
      jobsOptions.job_categories.forEach(category => {
        var main = <option key={category.name} style={{borderBottom: '1px solid gray', borderTop : '1px solid gray'}} disabled value={category.name}>{category.name}</option>
        categoriesList.push(main)
        category.subcategories.forEach(subcategory => {
          // subcategoryList.push(subcategory)
          var subkey = category.name + subcategory.id
          var sub =
            <option key={subkey} value={subcategory.id}>&#160;&#160;&#160;&#160;{subcategory.name}</option>
            categoriesList.push(sub)
        })
      })
    }

    var newCategoryList = [<option key="select" value="">選択</option>]
    newCategoryList.push(categoriesList)

    var positions = [<option value=''>選択</option>]
    if(jobsOptions.positions) {
      for(let option of jobsOptions.positions) {
        positions.push(<option value={String(option.id)}>{option.name}</option>)
      }
    }

    var workHoursErrorMessage = false
    if (shifts.length === 0) {
      workHoursErrorMessage = true
    }

    var trainingSalaryMinError = false
    var trainingSalaryMaxError = false
    if (submitted) {
      trainingSalaryMinError = !((trainingSalaryMax === "" && trainingSalaryMin === "") || parseInt(trainingSalaryMin) >= 750);
      trainingSalaryMaxError = !((trainingSalaryMax === "" && trainingSalaryMin === "") || (parseInt(trainingSalaryMax) <= 25000 && parseInt(trainingSalaryMin) <= parseInt(trainingSalaryMax)));
    }

    var trainingDurationValid;
    if (trainingDuration === "" || trainingDuration === null) {
      trainingDurationValid = true
    }
    else trainingDurationValid = parseInt(trainingDuration) > 0;

    var viewPreferredCertificates = type !== "add" ? DashboardTranslations.not_applicable_text : ""
    if (preferredCertificates.length > 0) {
      if (jobsOptions && jobsOptions.certificates) {
        viewPreferredCertificates = jobsOptions.certificates.map((item, index) => {
          if (preferredCertificates.indexOf(parseInt(item.id)) >= 0) {
            return item.name;
          }
        })
        viewPreferredCertificates = viewPreferredCertificates.filter(item => item && item.length > 0)
        viewPreferredCertificates = viewPreferredCertificates.join(",")
      }
    }


    let viewSsvIndustries = "";
    if(ssvIndustry) {
      viewSsvIndustries = this.state.ssvIndustriesSimpleObj[ssvIndustry];
    }

    console.log({q: this.props.type})

    var addJobButton =
      <button className="btn btn-primary button-inner" disabled={this.props.type === 'view'} onClick={() => this.addShiftsButton()}>
        {DashboardTranslations.add_job_add_shift_button_text}
      </button>

    return (
      <div className="add-jobs-inner-container" style={{display: this.props.currentStage === 3 ? "block" : "none"}}>

        {(this.props.type === "add") &&
        <div className="work-hours-heading">
          仕事内容
        </div>
        }

        <div className="input-box-container">
          <div className="label-container">
            {DashboardTranslations.add_job_work_label}
            {this.isMandatory('work')}
          </div>
          {submitted === true && work === "" && <div className="error-message-container">{DashboardTranslations.add_job_work_error_message}</div>}
          <div className="drop-list" style={{
            opacity: (this.props.type === 'view') ? 0.7 : 1,
          }}>
            <select
                name="work"
                className={(submitted === true && work === "") ? 'single-select error': 'single-select'}
                disabled={this.props.type === 'view'}
                value={work}
                onChange={(e) => this.handleChange(e)}
            >
              {newCategoryList}
            </select>
          </div>
          <div className="special-explanatory-text">該当する職種がない場合は<a href="https://www.workjapan.jp/inquiry/" target="_blank">お問い合わせ</a>ください。</div>
        </div>


        {
          !this.isDisabled('position') &&
          <div className="select-container">
            <div className="label-container">
              ポジション
              {this.isMandatory('position')}
            </div>
            <div className="drop-list" style={{
              opacity: (this.isDisabled('position') || this.props.type === 'view') ? 0.7 : 1,
            }}>
              <select
                name="position"
                className="single-select"
                disabled={(this.isDisabled('position') || this.props.type === 'view')}
                value={this.state.position}
                onChange={(e) => this.setState({position: e.target.value})}
              >
                {positions}
              </select>
            </div>
            <div className="explanatory-text">
              該当するポジションがない場合は仕事詳細欄にご入力ください。
            </div>
          </div>
        }

        <SelectDropdown
          label={DashboardTranslations.add_job_interview_in_charge_label}
          errorMessage={DashboardTranslations.add_job_interview_in_charge_error_message}
          error={submitted === true && inCharge === ""}
          disabled={this.props.type === 'view'}
          name="inCharge"
          value={inCharge}
          explanatoryText={DashboardTranslations.add_job_interview_in_charge_explanatory_text}
          mandatoryText={this.isMandatory('inCharge')}
          tooltipText={getTooltipMessage('add_job_in_charge_tooltip_text')}
          options={inchargeOptions}
          handleChange={this.handleChange.bind(this)}
        />

        {!this.isDisabled('jobDetail') && <div className="input-box-container">
          <div style={{
              opacity: (this.isDisabled('jobDetail') || this.props.type === 'view') ? 0.7 : 1,
            }}>
            <InputBox
              id='job-detail'
              readOnly={true}
              textArea={true}
              disabled={this.isDisabled('jobDetail') || this.props.type === 'view'}
              mandatoryText={this.isMandatory('jobDetail')}
              label='仕事詳細'
              value={this.state.jobDetail}
              placeholder={this.state.tempDetail}
              handleClick={(e) => {
                this.setState({textAreaModal: true})
                if (this.state.jobDetail === '') {
                  this.setState({jobDetail: this.state.tempDetail})
                }
              }}
            />
          </div>
        </div>}

        {
          (
            !this.isDisabled('preferredExperienceYear') || !this.isDisabled('preferredQualification') ||
            !this.isDisabled('preferredSkills') || !this.isDisabled('preferredCertificates') ||
            !this.isDisabled('preferredJPLevel') || !this.isDisabled('preferredEnLevel') ||
            !this.isDisabled('preferredVisaTypes')
          ) &&
          <div className="work-hours-heading">
            応募資格
          </div>
        }

        {
          !this.isDisabled('preferredExperienceYear') &&
          <InputBox
            label='仕事経験（年以上・半角)'
            inputType="number"
            value={this.state.preferredExperienceYear}
            disabled={this.isDisabled('preferredExperienceYear') || this.props.type === 'view'}
            mandatoryText={this.isMandatory('preferredExperienceYear')}
            name="workExperience"
            handleChange={(e) => this.setState({preferredExperienceYear: e.target.value})}
          />
        }

        {
          !this.isDisabled('preferredQualification') &&
          <SelectDropdown
            label='最終学歴'
            name="qualification"
            value={this.state.preferredQualification}
            disabled={this.isDisabled('preferredQualification') || this.props.type === 'view'}
            mandatoryText={this.isMandatory('preferredQualification')}
            options={qualificationOptions}
            handleChange={(e) => this.setState({preferredQualification: e.target.value})}
          />
        }


        {
          !this.isDisabled('preferredSkills') &&
          <MultipleSelect
            label='スキル'
            items={this.state.jobsOptions.skills}
            selectedItems={this.state.preferredSkills}
            disabled={this.isDisabled('preferredSkills') || this.props.type === 'view'}
            expand={this.state.multipleSelectionExpandDiv.includes('skill')}
            expandCollapse={(e) => this.expandCollapse('skill')}
            openMultipleSelectionModal={(e) => this.openMultipleSelectionModal('skill')}
          />
        }

        {
          !this.isDisabled('preferredCertificates') &&
          <MultipleSelect
            label='希望保有資格'
            items={this.state.jobsOptions.certificates}
            selectedItems={this.state.preferredCertificates}
            disabled={this.isDisabled('preferredCertificates') || this.props.type === 'view'}
            expand={this.state.multipleSelectionExpandDiv.includes('certificate')}
            expandCollapse={(e) => this.expandCollapse('certificate')}
            openMultipleSelectionModal={(e) => this.openMultipleSelectionModal('certificate')}
          />
        }


        {
          !this.isDisabled('preferredJPLevel') &&
          <SelectDropdown
            errorMessage={this.state.errorMapping.preferredJPLevel}
            error={!!this.state.errorMapping.preferredJPLevel}
            disabled={this.isDisabled('preferredJPLevel') || this.props.type === 'view'}
            label='日本語力'
            mandatoryText={this.isMandatory('preferredJPLevel')}
            name="jpLevel"
            value={this.state.preferredJPLevel}
            options={jpLevelOptions}
            handleChange={(e) => this.setState({preferredJPLevel: e.target.value}, this.checkError)}
          />
        }

        {
          !this.isDisabled('preferredEnLevel') &&
          <SelectDropdown
            label='英語力'
            mandatoryText={this.isMandatory('preferredEnLevel')}
            name="enLevel"
            value={this.state.preferredEnLevel}
            disabled={this.isDisabled('preferredEnLevel') || this.props.type === 'view'}
            options={engLevelOptions}
            handleChange={(e) => this.setState({preferredEnLevel: e.target.value})}
          />
        }


        {
          !this.isDisabled('preferredVisaTypes') &&
          <MultipleSelect
            label='歓迎する在留資格'
            items={this.state.jobsOptions.visa_types}
            selectedItems={this.state.preferredVisaTypes}
            disabled={this.isDisabled('preferredVisaTypes') || this.props.type === 'view'}
            expand={this.state.multipleSelectionExpandDiv.includes('visa')}
            expandCollapse={(e) => this.expandCollapse('visa')}
            openMultipleSelectionModal={(e) => this.openMultipleSelectionModal('visa')}
          />
        }

        {
          (
            !this.isDisabled('ssvIndustries') ||
            !this.isDisabled('acceptanceCertificates')
          ) &&
          <div className="work-hours-heading">
            特定技能採用に関する項目
          </div>
        }
        {
          !this.isDisabled('ssvIndustries') &&
          <SelectDropdown
            label='対象職種（合格試験）'
            name="ssv-industry"
            value={this.state.ssvIndustry}
            disabled={this.isDisabled('ssvIndustries') || this.props.type === 'view'}
            options={ssvOptions}
            handleChange={(e) => this.setState({ssvIndustry: e.target.value})}
          />
        }

        {
          !this.isDisabled('acceptanceCertificates') &&
          <div className="input-box-container">
            <div className="label-container">歓迎する人材のタイプ</div>
            <div className="drop-list" style={{
                opacity: (this.isDisabled('acceptanceCertificates') || this.props.type === 'view') ? 0.7 : 1,
              }}>
              {
                acceptanceOptions.map(
                  item =>
                    <div style={{textAlign: 'left'}}>
                      <Checkbox
                        checked={this.state.acceptanceCertificates.includes(item.value)}
                        disabled={this.isDisabled('acceptanceCertificates') || this.props.type === 'view'}
                        onChange={(e) => this.handleAcceptanceItem(item)}
                        color="primary"
                        style={{marginTop: '3px'}}
                        value={item.value}
                      />
                      <label style={{marginBottom: 'none', opacity: (this.isDisabled('acceptanceCertificates') || this.props.type === 'view') ? 0.7 : 1}}>{item.label}</label>
                    </div>
                )
              }
            </div>
          </div>
        }


        {
          !this.isDisabled('willSupportVisaChange') &&
          <div>
            <div className="work-hours-heading">
              在留資格の変更・更新について
              {this.isMandatory('willSupportVisaChange')}
            </div>

            <SelectDropdown
              errorMessage={this.state.errorMapping.willSupportVisaChange}
              error={!!this.state.errorMapping.willSupportVisaChange}
              disabled={this.isDisabled('willSupportVisaChange') || this.props.type === 'view'}
              label=''
              name="visaChangeSupport"
              value={this.state.willSupportVisaChange}
              options={visaChangeOptions}
              handleChange={(e) => this.setState({willSupportVisaChange: e.target.value}, this.checkError)}
            />
          </div>
        }

        <div className="work-hours-heading">
          給与
        </div>

        {
          this.state.jobType && ['SSV', 'FT'].includes(this.state.jobType) &&
          <div style={{
            textAlign: 'left',
            marginTop: '10px',
            fontSize: '11px',
            color: '#9B9B9B'
          }}>
            月給か年収のどちらかを入力してください。
          </div>
        }

        {
          !this.isDisabled('hourlySalaryMin') &&
          <div className="multiple-select-container">
            <InputBox
              label={DashboardTranslations.add_job_salary_min_label}
              errorMessage={DashboardTranslations.add_job_salary_min_error_message}
              disabled={this.isDisabled('hourlySalaryMin') || this.props.type === 'view'}
              error={submitted === true && (salaryMin === "" || parseInt(salaryMin) < parseInt(minHourlySalary)) && !this.isDisabled('hourlySalaryMin')}
              tooltipText={getTooltipMessage('add_job_salary_min_tooltip_text')}
              type="multiline"
              inputType="number"
              value={salaryMin}
              explanatoryText={DashboardTranslations.add_job_minimun_salary_by_prefecture_explanatory_text}
              mandatoryText={this.isMandatory('salaryMin')}
              name="salaryMin"
              leaveErrorSpace={leaveErrorSpaceSalary}
              handleChange={this.handleChange.bind(this)}
            />
            <InputBox
              label={DashboardTranslations.add_job_salary_max_label}
              errorMessage={DashboardTranslations.add_job_salary_max_error_message}
              disabled={this.isDisabled('hourlySalaryMax') || this.props.type === 'view'}
              error={submitted === true && (salaryMax === "" || parseInt(salaryMax) < parseInt(salaryMin) || parseInt(salaryMax) > 25000) && !this.isDisabled('hourlySalaryMax')}
              type="multiline"
              inputType="number"
              leaveErrorSpace={leaveErrorSpaceSalary}
              value={salaryMax}
              name="salaryMax"
              mandatoryText={this.isMandatory('salaryMax')}
              handleChange={this.handleChange.bind(this)}
            />
          </div>
        }

        {
          !this.isDisabled('monthlySalaryMin') &&
          <div className="multiple-select-container">
            <InputBox
              errorMessage={this.state.errorMapping.monthlySalaryMin}
              error={!!this.state.errorMapping.monthlySalaryMin}
              label='最低月給（¥/月・半角）'
              mandatoryText={this.isMandatory('monthlySalaryMin')}
              type="multiline"
              inputType="number"
              value={this.state.monthlySalaryMin}
              disabled={this.isDisabled('monthlySalaryMin') || this.props.type === 'view'}
              name="salaryMinMonthly"
              handleChange={(e) => this.setState({monthlySalaryMin: e.target.value}, this.checkError)}
            />
            <InputBox
              errorMessage={this.state.errorMapping.monthlySalaryMax}
              error={!!this.state.errorMapping.monthlySalaryMax}
              label='最大月給（¥/月・半角）'
              mandatoryText={this.isMandatory('monthlySalaryMax')}
              type="multiline"
              inputType="number"
              value={this.state.monthlySalaryMax}
              disabled={this.isDisabled('monthlySalaryMax') || this.props.type === 'view'}
              name="salaryMaxMonthly"
              handleChange={(e) => this.setState({monthlySalaryMax: e.target.value}, this.checkError)}
            />
          </div>
        }

        {
          !this.isDisabled('annualSalaryMin') &&
          <div className="multiple-select-container">
            <InputBox
              errorMessage={this.state.errorMapping.annualSalaryMin}
              error={!!this.state.errorMapping.annualSalaryMin}
              label='最低年収（¥/年・半角）'
              mandatoryText={this.isMandatory('annualSalaryMin')}
              type="multiline"
              inputType="number"
              value={this.state.annualSalaryMin}
              disabled={this.isDisabled('annualSalaryMin') || this.props.type === 'view'}
              name="salaryMinAnnually"
              handleChange={(e) => this.setState({annualSalaryMin: e.target.value}, this.checkError)}
            />
            <InputBox
              errorMessage={this.state.errorMapping.annualSalaryMax}
              error={!!this.state.errorMapping.annualSalaryMax}
              label='最大年収（¥/年・半角）'
              mandatoryText={this.isMandatory('annualSalaryMax')}
              type="multiline"
              inputType="number"
              value={this.state.annualSalaryMax}
              disabled={this.isDisabled('annualSalaryMax') || this.props.type === 'view'}
              name="salaryMaxAnnually"
              handleChange={(e) => this.setState({annualSalaryMax: e.target.value}, this.checkError)}
            />
          </div>
        }

        {
          !this.isDisabled('bonusPaidPerYear') &&
          <div className="multiple-select-container">
            <InputBox
              label='賞与（回/年・半角）'
              type="multiline"
              inputType="number"
              value={this.state.bonusPaidPerYear}
              disabled={this.isDisabled('bonusPaidPerYear') || this.props.type === 'view'}
              name="bonusPaidPerYear"
              handleChange={(e) => this.setState({bonusPaidPerYear: e.target.value})}
            />
          </div>
        }

        {
          !this.isDisabled('raisePerYear') &&
          <div className="multiple-select-container">
            <InputBox
              label='昇給（回/年・半角）'
              type="multiline"
              inputType="number"
              value={this.state.raisePerYear}
              disabled={this.isDisabled('raisePerYear') || this.props.type === 'view'}
              name="raisePerYear"
              handleChange={(e) => this.setState({raisePerYear: e.target.value})}
            />
          </div>
        }

        {
          !this.isDisabled('overtimeHoursPerMonth') &&
          <div className="multiple-select-container">
            <InputBox
              label='時間外勤務時間（1ヶ月）'
              type="multiline"
              inputType="number"
              value={this.state.overtimeHoursPerMonth}
              disabled={this.isDisabled('overtimeHoursPerMonth') || this.props.type === 'view'}
              name="overtimeHoursPerMonth"
              handleChange={(e) => this.setState({overtimeHoursPerMonth: e.target.value})}
            />
          </div>
        }


        {
          !this.isDisabled('trainingDuration') &&
          <InputBox
            error={!trainingDurationValid}
            errorMessage={DashboardTranslations.add_job_training_duration_error_message}
            label={DashboardTranslations.add_job_training_duration_label}
            name="trainingDuration"
            inputType="number"
            value={trainingDuration}
            disabled={this.isDisabled('trainingDuration') || this.props.type === 'view'}
            handleChange={this.handleChange.bind(this)}
          />
        }

        <div className="multiple-select-container">
          <InputBox
            error={trainingSalaryMinError}
            errorMessage={DashboardTranslations.add_job_training_salary_min_error_message}
            label={DashboardTranslations.add_job_training_salary_min_label}
            type="multiline"
            disabled={this.props.type === 'view'}
            value={trainingSalaryMin}
            leaveErrorSpace={leaveErrorSpaceTrainingSalary}
            inputType="number"
            name="trainingSalaryMin"
            handleChange={this.handleChange.bind(this)}
          />
          <InputBox
            error={trainingSalaryMaxError}
            errorMessage={DashboardTranslations.add_job_training_salary_max_error_message}
            label={DashboardTranslations.add_job_training_salary_max_label}
            disabled={this.props.type === 'view'}
            type="multiline"
            value={trainingSalaryMax}
            leaveErrorSpace={leaveErrorSpaceTrainingSalary}
            inputType="number"
            name="trainingSalaryMax"
            handleChange={this.handleChange.bind(this)}
          />
        </div>

        <SelectDropdown
          error={submitted === true && transportationCovered === ""}
          errorMessage={DashboardTranslations.add_job_page_transportation_covered_error_message}
          disabled={this.props.type === "view"}
          label={DashboardTranslations.add_job_page_transportation_covered_label}
          name="transportationCovered"
          value={transportationCovered}
          options={transportationCoveredOptions}
          mandatoryText={this.isMandatory('transportationCovered')}
          handleChange={this.handleChange.bind(this)}
        />

        <div className="work-hours-heading">
          {DashboardTranslations.add_job_work_hours_heading}
          {this.isMandatory('workHours')}
        </div>
        <Shift disabled={this.props.type === 'view'} addShiftsSubmitted={this.state.addShiftsSubmitted} showError={submitted && workHoursErrorMessage} addJobButton={addJobButton} selectDay={this.selectDay.bind(this)} selectedDays={this.state.selectedDays} endTime={endTime} toValue={startTime} handleChange={this.handleChange.bind(this)} />

        {this.state.shifts.length > 0 && <div className="work-hours-list-container">
          {shifts}
        </div>}

        {submitted && workHoursErrorMessage && <div className="special-work-hours-error-message" style={{fontSize: 10, color: '#e06e6f'}}>{DashboardTranslations.add_job_add_shift_error_message}</div>}

        {
          !this.isDisabled('remoteWork') &&
          <div className='input-box-container'>
            <div className="label-container">リモートワーク</div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap'}}>
              <div style={{display: 'flex', alignItems: 'center', textWrap: 'nowrap'}}>
                <Radio
                  style={{marginBottom: 0, width: '10%'}}
                  color="primary"
                  size="medium"
                  checked={this.state.remoteWork === 'Fully'}
                  disabled={this.isDisabled('remoteWork') || this.props.type === 'view'}
                  value={'Fully'}
                  onChange={(e) => this.setState({remoteWork: 'Fully'})}
                />
                <span style={{opacity: (this.isDisabled('remoteWork') || this.props.type === 'view') ? 0.7 : 1}}>完全リモート</span>
              </div>
              <div style={{display: 'flex', alignItems: 'center', textWrap: 'nowrap'}}>
                <Radio
                  style={{marginBottom: 0, width: '10%'}}
                  color="primary"
                  size="medium"
                  checked={this.state.remoteWork === 'Partially'}
                  disabled={this.isDisabled('remoteWork') || this.props.type === 'view'}
                  value={'Partially'}
                  onChange={(e) => this.setState({remoteWork: 'Partially'})}
                />
                <span style={{opacity: (this.isDisabled('remoteWork') || this.props.type === 'view') ? 0.7 : 1}}>一部リモート</span>
              </div>
              <div style={{display: 'flex', alignItems: 'center', textWrap: 'nowrap'}}>
                <Radio
                  style={{marginBottom: 0, width: '10%'}}
                  color="primary"
                  size="medium"
                  checked={this.state.remoteWork === 'No'}
                  disabled={this.isDisabled('remoteWork') || this.props.type === 'view'}
                  value={'No'}
                  onChange={(e) => this.setState({remoteWork: 'No'})}
                />
                <span style={{opacity: (this.isDisabled('remoteWork') || this.props.type === 'view') ? 0.7 : 1}}>なし</span>
              </div>
            </div>
          </div>
        }


        {
          !this.isDisabled('holidays') &&
          <div>
            <div className="work-hours-heading">
              休日・休暇
            </div>

            <div className="input-box-container">
              <div className="drop-list" style={{
                opacity: (this.isDisabled('holidays') || this.props.type === 'view') ? 0.7 : 1,
              }}>
                {
                  holidayOptions.map(
                    item =>
                      <div style={{textAlign: 'left'}}>
                        <Checkbox
                          checked={this.state.holidays.includes(item.value)}
                          disabled={this.isDisabled('holidays') || this.props.type === 'view'}
                          onChange={(e) => this.handleHolidayItem(item)}
                          color="primary"
                          style={{marginTop: '3px'}}
                          value={item.value}
                        />
                        <label style={{marginBottom: 'none', opacity: (this.isDisabled('holidays') || this.props.type === 'view') ? 0.7 : 1}}>{item.label}</label>
                      </div>
                  )
                }
              </div>
            </div>
          </div>
        }


        {
          !this.isDisabled('socialInsuranceProvided') &&
          <div>
            <div className="work-hours-heading">
              福利厚生
            </div>

            <div className="multiple-select-container">
              <SelectDropdown
                disabled={this.isDisabled('socialInsuranceProvided') || this.props.type === 'view'}
                label='社会保険'
                name="socialInsurance"
                value={this.state.socialInsuranceProvided}
                options={socialInsuranceOptions}
                handleChange={(e) => this.setState({socialInsuranceProvided: e.target.value})}
              />
            </div>
          </div>
        }


        {
          (
            !this.isDisabled('company') || !this.isDisabled('companyNameJp') ||
            !this.isDisabled('companyNameEn') || !this.isDisabled('companyIndustryType') ||
            !this.isDisabled('companyEmployee') || !this.isDisabled('nationalities') ||
            !this.isDisabled('website')
          ) &&
          <div className="work-hours-heading">
            会社情報
          </div>
        }

        {
          (!this.isDisabled('company')) &&
          <SelectDropdown
            label='登録済みの会社データを利用する。'
            explanatoryText='会社情報をを新規登録する場合は「会社名」より入力開始してください。'
            name="companyName"
            value={this.state.company}
            disabled={this.isDisabled('company') || this.props.type === 'view' || this.props.edit}
            options={companyOptions}
            handleChange={(e) => this.updateCompany(e.target.value) || !!this.state.company}
          />
        }

        {
          !this.isDisabled('companyNameJp') &&
          <InputBox
            errorMessage={this.state.errorMapping.companyNameJp}
            error={!!this.state.errorMapping.companyNameJp}
            disabled={this.isDisabled('companyNameJp') || this.props.type === 'view' || !!this.state.company}
            label='会社名（日本語名）'
            mandatoryText={this.isMandatory('companyNameJp')}
            name="companyNameJp"
            inputType="text"
            value={this.state.companyNameJp}
            handleChange={(e) => this.setState({companyNameJp: e.target.value}, this.checkError)}
          />
        }

        {
          !this.isDisabled('companyNameEn') &&
          <InputBox
            errorMessage={this.state.errorMapping.companyNameEn}
            error={!!this.state.errorMapping.companyNameEn}
            disabled={this.isDisabled('companyNameEn') || this.props.type === 'view' || !!this.state.company}
            label='会社名（英語名）'
            mandatoryText={this.isMandatory('companyNameEn')}
            name="companyNameEn"
            inputType="text"
            value={this.state.companyNameEn}
            handleChange={(e) => this.setState({companyNameEn: e.target.value}, this.checkError)}
          />
        }

        {
          !this.isDisabled('companyIndustryType') &&
          <SelectDropdown
            errorMessage={this.state.errorMapping.companyIndustryType}
            error={!!this.state.errorMapping.companyIndustryType}
            disabled={this.isDisabled('companyIndustryType') || this.props.type === 'view' || !!this.state.company}
            label='業種'
            mandatoryText={this.isMandatory('companyIndustryType')}
            name="companyIndustryType"
            value={this.state.companyIndustryType}
            options={industriesOptions}
            handleChange={(e) => this.setState({companyIndustryType: e.target.value}, this.checkError)}
          />
        }

        {
          !this.isDisabled('companyEmployee') &&
          <div className="multiple-select-container">
            <InputBox
              errorMessage={this.state.errorMapping.companyEmployee}
              error={!!this.state.errorMapping.companyEmployee}
              disabled={this.isDisabled('companyEmployee') || this.props.type === 'view' || !!this.state.company}
              label='社員数（人）'
              mandatoryText={this.isMandatory('companyEmployee')}
              type="multiline"
              value={this.state.companyEmployee}
              inputType="number"
              name="companyEmployee"
              handleChange={(e) => this.setState({companyEmployee: e.target.value}, this.checkError)}
            />
          </div>
        }

        {
          !this.isDisabled('nationalities') &&
          <MultipleSelect
            label='外国人社員の国籍'
            items={this.state.jobsOptions.default_nationality_choices}
            selectedItems={this.state.nationalities}
            disabled={this.isDisabled('nationalities') || this.props.type === 'view' || !!this.state.company}
            expand={this.state.multipleSelectionExpandDiv.includes('nationality')}
            expandCollapse={(e) => this.expandCollapse('nationality')}
            openMultipleSelectionModal={(e) => this.openMultipleSelectionModal('nationality')}
          />
        }

        {
          !this.isDisabled('website') &&
          <InputBox
            errorMessage={this.state.errorMapping.website}
            error={!!this.state.errorMapping.website}
            disabled={this.isDisabled('website') || this.props.type === 'view' || !!this.state.company}
            label='ホームページ'
            value={this.state.website}
            inputType="text"
            name="companyWebsite"
            handleChange={(e) => this.setState({website: e.target.value}, this.checkError)}
          />
        }


        {(this.props.type === "add" || this.state.pictures.length > 0) &&
          <div className="work-hours-heading">
            {DashboardTranslations.add_job_page_work_location_image_heading}
          </div>
        }

        {(this.props.type === "add" || this.state.pictures.length > 0) &&
          <div className="work-hours-sub-heading">
            {DashboardTranslations.add_job_page_work_location_image_sub_heading}
          </div>
        }


        {this.props.type !== "view" && <ImageUploader
          withIcon={true}
          withPreview={true}
          buttonText={DashboardTranslations.add_job_page_choose_image_button_text}
          onChange={this.onDrop}
          label={DashboardTranslations.add_job_page_choose_image_description_text}
          imgExtension={['.jpg', '.png', '.jpeg']}
          maxFileSize={20971520}
        />}

        {this.props.type === "add" && this.state.noImagesAdded && work !== "" &&
          <div className="default-image-text" style={{color: "red", fontSize: 12}}>
            画像が何も選択されていない場合は、本画像が求職者向けアプリで表示されます。
          </div>
        }
        {((this.props.type === "add" || this.props.type === "copy") && !this.props.isDraftView) && this.state.defaultWorkImages.length > 0 && this.state.noImagesAdded && work !== "" &&
          <div style={{width: 350, margin: "0px auto 20px", maxWidth: '100%'}} className="carousel-container">
            <img src={this.state.defaultWorkImages[0].url} style={{width: 310, margin: "10px auto", maxWidth: '100%'}}/>
          </div>
        }

        {this.props.type === "add" && this.state.defaultWorkImages.length > 0 && this.props.isDraftView &&
          <div style={{width: 350, margin: "20px auto"}} className="carousel-container isDraftView-class">
            <Carousel disabled={this.props.type === 'view'} imgUrls={carousel} ids={carouselIds} deleteOldImages={this.deleteOldImages.bind(this)} noImagesAdded={this.state.noImagesAdded} />
          </div>
        }

        {this.props.type !== "add" && type !== "copy" && this.state.defaultWorkImages.length > 0 &&
          <div style={{width: 350, margin: "20px auto"}} className="carousel-container">
            <Carousel disabled={this.props.type === 'view'} imgUrls={carousel} ids={carouselIds} deleteOldImages={this.deleteOldImages.bind(this)} noImagesAdded={this.state.noImagesAdded} />
          </div>
        }

        <div className="work-hours-heading">{DashboardTranslations.add_job_sales_merit_heading}
          <Tooltip title={getTooltipMessage('add_job_sales_merit_tooltip_text')} placement="top">
            <img alt="tooltip" className="tooltip-text" src={HelpIcon} />
          </Tooltip>
        </div>
        <SelectDropdown
          error={false}
          label={DashboardTranslations.add_job_copy_from_label}
          name="selectedCopyFrom"
          disabled={this.props.type === 'view'}
          value={selectedCopyFrom}
          tooltipText={getTooltipMessage('add_job_sales_merit_copy_merit_tooltip_text')}
          options={copyFromList}
          handleChange={this.selectCopyFrom.bind(this)}
        />
        <div className="merits-container">
          <div>
            {meritsLeft}
          </div>
          <div>
            {meritsRight}
          </div>
        </div>

        <ErrorModal error={errorMessage} handleClose={this.handleErrorClose.bind(this)} showError={showError}/>

        <Modal
          open={this.state.textAreaModal}
          onClose={() => this.setState({textAreaModal: false})}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="outcome-modal-container">
            <div className="outcome-modal-inner">
              <div className="responsive-header"  style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                <div className="login-header-text">
                  仕事詳細
                </div>
              </div>
              <form className="form">
                <InputBox
                    textArea={true}
                    label=''
                    placeholder={''}
                    value={this.state.jobDetail}
                    handleChange={(e) => {
                      this.setState({jobDetail: e.target.value})
                    }}
                    height='100%'
                    isFullHeight
                />
                <div
                  className='clickable-link'
                  onClick={(e) => {
                    this.props.maintainHeight('job-detail');
                    this.setState({textAreaModal: false});
                  }}
                >
                  保存する
                </div>
              </form>
            </div>
          </div>
        </Modal>

        <Modal
          open={this.state.multipleSelectionModal}
          onClose={() => this.setState({multipleSelectionModal: false})}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="outcome-modal-container" style={{
            // width: 'max-content',
            minWidth: '350px'
          }}>
            <div className="outcome-modal-inner" style={{padding: '15px'}}>
              <div className='scroll-area' style={{overflowY: 'auto', maxHeight: 'calc(100vh - 300px)'}}>
                {
                  this.state.multipleSelection.items &&
                  this.state.multipleSelection.items.map(
                    item =>
                      <div style={{display: 'flex', alignItems: 'center', marginTop: '3px'}}>
                        <Checkbox
                          checked={this.state.multipleSelection.selectedItems.includes(item.id)}
                          onChange={(e) => this.handleSelectedItem(e, item)}
                          color="primary"
                          disabled={
                            this.state.multipleSelection.field === 'ssv-industry' && 
                            !this.state.multipleSelection.selectedItems.includes(item.id) && 
                            this.state.multipleSelection.selectedItems.length > 0
                          }
                          // style={{}}
                          value={item.id}
                        />
                        <label style={{marginBottom: '0', marginLeft: '10px',}}>{item.name}</label>
                      </div>
                  )
                }
              </div>
              <div
                className='clickable-link'
                onClick={this.updateSelectedItems.bind(this)}
              >
                保存する
              </div>
            </div>
          </div>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token
    }
};

AddJob = connect(mapStateToProps)(AddJob);


export default AddJob;
