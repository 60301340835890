import React, { Component } from 'react';
import InnerHeader from '../components/InnerHeader.js'
import InputBox from '../components/InputBox.js'
import { connect } from 'react-redux';
import { fetchUserDetails, resendVerificationCode, updateUserDetailsAPI, verifyMobileNumberAPI } from '../helperFunctions/dataServices.js'
import { ENV_TYPE } from '../../constants';
import Modal from '@material-ui/core/Modal';
import Joyride from 'react-joyride';
import ErrorModal from '../components/ErrorModal'
import { logout } from '../../actions';
import ScaleLoader from 'react-spinners/ScaleLoader';
import DashboardTranslations from '../../DashboardTranslations.js';
import { getErrorMessage } from '../../messages/errors';
import SuccessModal from '../components/SuccessModal'
import ReactGA from 'react-ga';
import { completedJoyride } from '../../actions';
import './styles/userSettings.scss';
import { emailRegex, nameRegex, passwordRegex } from '../../regex';
import {getTooltipMessage} from "../../messages/tooltips";

var mobileNumberArray = ["050"]
var mobileNumberArray2 = ["50"]
class UserSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      displayName: "",
      email: "",
      mobileNumber: "",
      submitted: false,
      changed: false,
      showVerifyModal: false,
      verifyCode: "",
      submitVerifyCode: false,
      type: "userSettings",
      mainKey: 0,
      submitPassword: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      successMessage: "",
      showDetailsSuccess: false,
      detailsSuccessMessage: "",
      showSuccess: false,
      showError: false,
      error: '',
      details: [],
      showMobileNumberErrorModal: false,
      steps: [
        {
          target: '.settings-form-container',
          content: '表示名と携帯番号を更新することができます。 ※SMS通知をご希望の方は、携帯電話のご登録をお忘れなく！',
          title: 'ここから自分のユーザー設定を変更できます。',
          disableBeacon: true,
          // placement: "right"
        },
      ]
    }
  }

  componentDidMount() {
    if (ENV_TYPE === "PROD") {
      this.initializeReactGA()
    }
    if (this.props.joyride.completed.indexOf("userSettings") > -1) {
      this.setState({steps: []});
    }
    this.fetchDetails()
  }

  initializeReactGA() {
    ReactGA.initialize('UA-104174852-1');
    ReactGA.pageview('/dashboard/user-settings');
  }

  handleJoyrideCallback(data) {
    if (data.status === "finished" && data.lifecycle === "complete") {
      var joyride = this.props.joyride.completed
      if (joyride.indexOf("userSettings") > -1) {
        // joyride = joyride.filter(item => item !== "userSettings")
      }
      else {
        joyride[joyride.length] = "userSettings"
      }
      this.props.dispatch(completedJoyride(joyride));
    }
  }

  handleClose = () => {
    this.props.dispatch(logout())
    this.props.history.push('/register')
  };

  handleDetailsClose = () => {
    this.setState({ showDetailsSuccess: false, detailsSuccessMessage: '' });
  };

  handleChange = event => {
    var mobilePattern = new RegExp(/^[0-9]*$/)
    var value = event.target.value
    if (event.target.name === "mobileNumber" || event.target.name === "verifyCode") {
      if (mobilePattern.test(value)) {
        this.setState({ [event.target.name]: value });
      }
    }
    else {
      this.setState({ [event.target.name]: value });
    }
  }

  handleCheckbox = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  backArrowOnClick() {
    this.props.history.goBack();
  }

  fetchDetails() {
    this.setState({loading: true})
    fetchUserDetails().then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        var firstName = response.data.first_name
        var lastName = response.data.last_name
        var displayName = response.data.display_name
        var email = response.data.email
        var mobileNumber = response.data.contact_num ? response.data.contact_num : ""
        this.setState({loading: false, details: response.data, firstName: firstName, lastName: lastName,
                            displayName: displayName, email: email, initialEmail: email, mobileNumber: mobileNumber})
      }
      else {
        this.setState({loading: false})
      }
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  verifyMobile() {
    this.setState({loading: true})
    resendVerificationCode().then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({loading: false, showVerifyModal: true})
      }
      else {
        this.setState({loading: false, showError: true, error: getErrorMessage('from_api_response', {'apiErrorMessage': response.data.error})})
      }
    })
    .catch((e) => {
      this.setState({loading: false, showError: true})
    })
  }

  verifyMobileNumber() {
    var payload = {}
    payload.code = this.state.verifyCode
    verifyMobileNumberAPI(payload).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({loading: false, showVerifyModal: false, showDetailsSuccess: true, detailsSuccessMessage: DashboardTranslations.user_profile_mobile_number_verified_success_message})
      }
      else {
        this.setState({loading: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""})
      }
    })
    .catch((e) => {
      this.setState({loading: false, showError: true})
    })
  }

  closeVerifyModal() {
    this.setState({showVerifyModal: false})
  }

  updateDetails(type) {
    var { confirmPassword, newPassword, firstName, lastName, displayName, mobileNumber, email, initialEmail } = this.state
    var payload = {}
    var sendRequest = false
    if (type === "password") {
      this.setState({submitPassword: true})
      if (confirmPassword === newPassword && passwordRegex.test(newPassword)) {
        payload.password = confirmPassword
        sendRequest = true
      }
    }
    else {
      if (mobileNumber === "" || ((mobileNumber.length === 10 && mobileNumber[0] !== "0" && mobileNumberArray2.indexOf(mobileNumber.substring(0,2)) < 0) || (mobileNumber.length === 11 && mobileNumberArray.indexOf(mobileNumber.substring(0,3)) < 0))) {
        this.setState({submitted: true})
        if (nameRegex.test(firstName) && nameRegex.test(lastName) && displayName !== "") {
          if (mobileNumber !== "") {
            var newMob
            if (mobileNumber.length === 10 && mobileNumber[0] !== "0") {
              newMob = "+81-"+mobileNumber
              payload.contact_num = newMob
              payload.display_contact_num = mobileNumber
            }
            else if (mobileNumber.length === 11) {
              newMob = mobileNumber.substring(1,11)
              newMob = "+81-" + newMob
              payload.contact_num = newMob
              payload.display_contact_num = mobileNumber
            }
          }
          if(initialEmail !== email && emailRegex.test(email)) {
            payload.email = email;
          }
          payload.first_name = firstName
          payload.last_name = lastName
          payload.display_name = displayName
          sendRequest = true
        }
      }
      else {
        if (mobileNumberArray2.indexOf(mobileNumber.substring(0,2)) < 0 && mobileNumberArray.indexOf(mobileNumber.substring(0,3)) < 0) {
          this.setState({submitted: true})
        }
        else {
          this.setState({showMobileNumberErrorModal: true})
        }
      }

    }
    if (sendRequest === true) {
      updateUserDetailsAPI(payload).then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          if (type === "password") {
            this.setState({showSuccess: true, successMessage: DashboardTranslations.user_settings_password_update_success_message})
          }
          else {
            this.setState({loading: false, showDetailsSuccess: true, detailsSuccessMessage: payload.email ? 'ユーザー情報を更新しました' : DashboardTranslations.user_settings_details_update_success_message,
             submitted: false, submitPassword: false}, () => this.fetchDetails())
          }
        }
        else {
          this.setState({loading: false,submitted: false, submitPassword: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""})
        }
      })
      .catch((e) => {
        this.setState({loading: false, showError: true, submitted: false, submitPassword: false})
      })
    }
  }

  handleErrorClose = () => {
    this.setState({ showError: false, error: '' });
  };

  handleMobileNumberError = () => {
    this.setState({ showMobileNumberErrorModal: false, error: '' });
  };

  toggleUserSettings() {
    var { type } = this.state
    this.setState({type: type === "password" ? "userSettings" : "password"})
  }

  render() {
    var { firstName, lastName, displayName, email, mobileNumber, submitted, type, showDetailsSuccess, detailsSuccessMessage,
          showError, showSuccess, showVerifyModal, verifyCode, submitVerifyCode, error, details, steps,
          newPassword, confirmPassword, currentPassword, submitPassword, successMessage, loading, mainKey, showMobileNumberErrorModal,
        } = this.state

    var mobileNumberError = true
    if (mobileNumber === "") {
      mobileNumberError = false
    }
    else if (mobileNumber.length === 10 && mobileNumber[0] !== "0") {
      mobileNumberError = false
    }
    else if (mobileNumber.length === 11) {
      if (mobileNumber[0] === "0") {
        mobileNumberError = false
      }
    }

    var actionLabelText = ""
    if (details.contact_num !== "" && details.contact_num !== null) {
      if (details.is_contact_num_verified === true) {
        actionLabelText = DashboardTranslations.user_settings_mobile_number_verified_text
      }
      else {
        actionLabelText = DashboardTranslations.user_settings_verify_text
      }
    }

    return (
      <div className="user-settings-container">
        <InnerHeader
          primary={true}
          history={this.props.history}
          headerText={type === "userSettings" ? DashboardTranslations.user_settings_header_text : DashboardTranslations.user_settings_password_header_text}
          backArrowOnClick={this.backArrowOnClick.bind(this)}
          buttonOneText={type !== "userSettings" ? DashboardTranslations.user_settings_header_text : DashboardTranslations.user_settings_password_header_text}
          buttonOneClick={this.toggleUserSettings.bind(this)}
        />
        <ScaleLoader sizeUnit={"px"} height={50}
          width={4} color={'#007AFF'} loading={loading}
        />

        {loading === false && <div className="toggle-user-settings-button" onClick={() => this.toggleUserSettings()}>
          {type !== "userSettings" ? DashboardTranslations.user_settings_header_text : DashboardTranslations.user_settings_password_header_text}
        </div>}

        {!loading && type==="userSettings" && <Joyride
          autoStart={true}
          run={true}
          key={mainKey}
          steps={steps}
          disableCloseOnEsc={true}
          disableOverlayClose={true}
          locale={{ back: '前へ', close: '閉じる', last: '閉じる', next: '次へ', skip: 'スキップ' }}
          continuous={true}
          // stepIndex={stepIndex}
          showStepsProgress={true}
          callback={this.handleJoyrideCallback.bind(this)}
        />}
        {!loading && type==="userSettings" && <div className="settings-form-container">
          <InputBox
            errorMessage={DashboardTranslations.user_settings_last_name_error_message}
            error={submitted === true && !nameRegex.test(lastName) ? true : false}
            label={DashboardTranslations.user_settings_last_name_label}
            name="lastName"
            value={lastName}
            handleChange={this.handleChange.bind(this)}
          />
          <InputBox
            errorMessage={DashboardTranslations.user_settings_first_name_error_message}
            error={submitted === true && !nameRegex.test(firstName) ? true : false}
            label={DashboardTranslations.user_settings_first_name_label}
            name="firstName"
            value={firstName}
            handleChange={this.handleChange.bind(this)}
          />
          <InputBox
            errorMessage={DashboardTranslations.user_settings_display_name_error_message}
            error={submitted === true && displayName === ""}
            label={DashboardTranslations.user_settings_display_name_label}
            name="displayName"
            value={displayName}
            handleChange={this.handleChange.bind(this)}
          />
          <InputBox
            errorMessage={DashboardTranslations.register_page_email_error_message}
            error={submitted === true && !emailRegex.test(email)}
            label={DashboardTranslations.user_settings_email_label}
            value={email}
            name="email"
            handleChange={this.handleChange.bind(this)}
          />
          <div style={{margin: "20px auto", display: "flex", justifyContent: "center"}}>
            <button className="btn btn-primary" onClick={() => this.updateDetails("details")}>{DashboardTranslations.user_settings_save_button_text}</button>
          </div>
        </div>}
        {!loading && type==="password" && <div className="password-form-container">
          <InputBox
            errorMessage={DashboardTranslations.user_settings_current_password_error_message}
            error={submitPassword === true && currentPassword === ""}
            label={DashboardTranslations.user_settings_current_password_label}
            name="currentPassword"
            inputType="password"
            value={currentPassword}
            handleChange={this.handleChange.bind(this)}
          />
          <InputBox
            errorMessage={DashboardTranslations.user_settings_new_password_error_message}
            error={submitPassword === true && !passwordRegex.test(newPassword)}
            label={DashboardTranslations.user_settings_new_password_label}
            name="newPassword"
            tooltipText={getTooltipMessage('user_settings_new_password_tooltip_text')}
            inputType="password"
            value={newPassword}
            explanatoryText={DashboardTranslations.register_page_password_explanatory_text}
            handleChange={this.handleChange.bind(this)}
          />
          <InputBox
            errorMessage={DashboardTranslations.user_settings_confirm_password_error_message}
            error={submitPassword === true && !passwordRegex.test(confirmPassword)}
            label={DashboardTranslations.user_settings_confirm_password_label}
            name="confirmPassword"
            tooltipText={getTooltipMessage('user_settings_confirm_password_tooltip_text')}
            inputType="password"
            value={confirmPassword}
            explanatoryText={DashboardTranslations.register_page_password_explanatory_text}
            handleChange={this.handleChange.bind(this)}
          />
          <div style={{margin: "20px auto", display: "flex", justifyContent: "center"}}>
            <button className="btn btn-primary" onClick={() => this.updateDetails('password')}>{DashboardTranslations.user_settings_update_password_text}</button>
          </div>
        </div>}
        <Modal
          open={showVerifyModal}
          onClose={this.closeVerifyModal.bind(this)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="verify-modal-container"
          style={{}}
        >
          <div className="dialog-container">
            <div className="verify-header">{DashboardTranslations.user_settings_verify_mobile_header}</div>
            <div className="description">確認コードを: {mobileNumber} へSMSで送信しました。</div>
            <div className="form-container">
              <InputBox
                errorMessage={DashboardTranslations.user_settings_verification_code_error_message}
                error={submitVerifyCode === true && verifyCode === ""}
                label={DashboardTranslations.user_settings_verification_code_label}
                name="verifyCode"
                value={verifyCode}
                handleChange={this.handleChange.bind(this)}
              />
            </div>
            <div className="resend-text">{DashboardTranslations.user_settings_didnt_recieve_code_text}
              <span className="resend-link" onClick={() => this.verifyMobile()}>{DashboardTranslations.user_settings_resend_code_text}</span>
            </div>
            <div className="submit-button-container">
              <div className="btn btn-primary" onClick={() => this.verifyMobileNumber()}>
                {DashboardTranslations.user_settings_verify_button_text}
              </div>
              <div className="btn btn-secondary" onClick={() => this.closeVerifyModal()}>
                {DashboardTranslations.user_settings_cancel_button_text}
              </div>
            </div>
          </div>
        </Modal>
        <SuccessModal successMessage={successMessage} handleClose={this.handleClose.bind(this)} showSuccess={showSuccess}/>
        <SuccessModal successMessage={detailsSuccessMessage} handleClose={this.handleDetailsClose.bind(this)} showSuccess={showDetailsSuccess}/>
        <ErrorModal error={error} handleClose={this.handleErrorClose.bind(this)} showError={showError}/>
        <ErrorModal error={getErrorMessage('error_message_for_valid_mobile')} handleClose={this.handleMobileNumberError.bind(this)} showError={showMobileNumberErrorModal}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token,
        joyride: state.joyride
    }
};

UserSettings = connect(mapStateToProps)(UserSettings);

export default UserSettings;
