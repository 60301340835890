import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeRoleAPI, removeMemberAPI, resendInvitationAPI } from '../helperFunctions/dataServices.js'
import DashboardTranslations from '../../DashboardTranslations.js';
import "react-toggle-switch/dist/css/switch.min.css"
import '../styles/memberCard.scss';
import {getErrorMessage} from "../../messages/errors";
import SuccessModal from "./SuccessModal";
import Select, { components } from 'react-select'
import PopupModal from './PopupModal.js';
import PopupIcon from '../../images/svg/popup_icon_1.svg'

class MemberCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: "",
      showSuccess: false,
      deleteShowConfirm: false,
    }
  }

  componentDidMount() {
    var role = "member"
    if (this.props.memberDetails.is_admin === true) {
      role = "admin"
    }
    this.setState({role : role})
  }

  handleChange = event => {
    if(!this.props.isAdmin) return;
    var value = event.value
    this.setState({ role: value }, () => this.changeRole(value));
  }

  resendInvitation() {
    const payload = {invitee_email: this.props.memberDetails.email}

    resendInvitationAPI(payload).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({ loading: false, showSuccess: true})
      }
      else {
        this.setState({loading: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""})
      }
    })
        .catch((e) => {
          this.setState({loading: false, showError: true})
        })
  }

  handleSuccess() {
    this.setState({showSuccess: false})
  }

  changeRole(isAdmin) {
    var payload = {}
    var isAdminValue = true
    if (isAdmin === "member") {
      isAdminValue = false
    }
    payload.is_admin = isAdminValue
    this.setState({loading: true})
    changeRoleAPI(payload, this.props.memberDetails.id).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({ loading: false})
      }
      else {
        this.setState({loading: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""})
      }
    })
    .catch((e) => {
      this.setState({loading: false, showError: true})
    })
  }

  removeUser() {
    if(!this.props.isAdmin) return
    this.setState({loading: true})
    removeMemberAPI(this.props.memberDetails.id).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({ loading: false})
        this.props.removeSuccess()
        this.props.fetchMemberDetails()
      }
      else {
        this.setState({loading: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""})
      }
    })
    .catch((e) => {
      this.setState({loading: false, showError: true})
    })
  }

  render() {
    var { role, showSuccess } = this.state
    var { user, memberDetails } = this.props
    var name = DashboardTranslations.team_page_invited_email_header
    var disabled = true
    if (memberDetails.display_name) {
      name = memberDetails.display_name
      disabled = !this.props.isAdmin
    }

    if (user.email === memberDetails.email) {
      disabled = true
    }

    const options = [
      {value: 'admin', label: '管理者（全機能にアクセスできます。）'},
      {value: 'member', label: '一般（お支払以外の全機能にアクセスできます。）'}
    ]

    const currentOption = options.find(opt => opt.value === role)

    return (
      <div className="member-card-container">
        {/* <div style={{
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
          backgroundColor: 'red',
          zIndex: 999
        }}>

        </div> */}
        <table style={{width: '100%'}}>
          <tbody>
          <tr>
            <td>
              <div className="name-container">
                <div className={`name ${memberDetails.display_name ? '' : 'name-invited'}`}>
                  {name}
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

        <div className="email-container">
          <div className="email">
            <div className="value">
              {memberDetails.email}
            </div>
          </div>
          <div className="role">
            <div className="label">
              {DashboardTranslations.team_page_role_label}
            </div>
            <Select
              label="Select"
              value={currentOption}
              classNames={{
                control: (state) =>
                  `select-role ${disabled ? 'select-disabled': ''}`,
                singleValue: (state) =>
                  `select-text ${disabled ? 'select-text-disabled': ''}`
              }}
              styles={{
                indicatorSeparator: (baseStyles, state) => ({
                  ...baseStyles,
                  width: 0,
                })
              }}
              components={{
                DropdownIndicator: dropProps => {
                  return (
                    components.DropdownIndicator && (
                      <components.DropdownIndicator {...dropProps}>
                        <svg xmlns="http://www.w3.org/2000/svg" style={{
                          transform: `scaleY(${dropProps.selectProps.menuIsOpen ? -1 : 1})`
                        }} width="17" height="17" viewBox="0 0 17 17" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2305 6.46017C14.4258 6.65543 14.4258 6.97201 14.2305 7.16727L9.23051 12.1673C9.03524 12.3625 8.71866 12.3625 8.5234 12.1673L3.5234 7.16727C3.32814 6.97201 3.32814 6.65543 3.5234 6.46017C3.71866 6.2649 4.03524 6.2649 4.23051 6.46017L8.87695 11.1066L13.5234 6.46017C13.7187 6.26491 14.0352 6.26491 14.2305 6.46017Z" fill="#787878"/>
                        </svg>
                      </components.DropdownIndicator>
                    )
                  );
                }
              }}
              isDisabled={disabled}
              options={options}
              onChange={(event) => this.handleChange(event)}
            />
            <div className="explanatory-text">ユーザーがログインすると役割が変更できます。</div>
          </div>
          <div className='button-container'>
            {this.props.isAdmin ? (memberDetails.display_name ?
                (
                  user.email !== memberDetails.email ? 
                  <div className="button button-red" onClick={() => this.setState({deleteShowConfirm: true})}>
                    削除する
                  </div>
                  : null
                )
                :
                <div className="button button-blue" onClick={() => this.resendInvitation()}>
                  招待を再送する
                </div>) : null
            }
          </div>
        </div>

        <SuccessModal
            successMessage='招待を再送しました'
            handleClose={this.handleSuccess.bind(this)}
            showSuccess={showSuccess}
        />
        <PopupModal
          showModal={this.state.deleteShowConfirm}
          popupIcon={PopupIcon}
          title='このユーザーを削除しますか?'
          subtitle={<p>一度削除すると、新たに招待しない限り、<br/>元に戻すことはできません。</p>}
          cancelText='戻る'
          confirmText='削除する'
          handleCancel={() => {
            this.setState({
              deleteShowConfirm: false
            })
          }}
          handleConfirm={() => {
            this.setState({
              deleteShowConfirm: false
            }, () => this.removeUser())
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        selectedScreen: state.selectedScreen,
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token,
        user: state.user
    }
};

MemberCard = connect(mapStateToProps)(MemberCard);

export default MemberCard;
