import React, { useCallback, useEffect, useState } from "react";
import "../styles/filterBar.scss";
import "overlayscrollbars/styles/overlayscrollbars.css";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import useEmblaCarousel from "embla-carousel-react";
import filterArrow from "../../images/svg/filter-arrow.svg";
import downArrow from "../../images/svg/down-new.svg";
import searchIcon from "../../images/svg/search.svg";
import downArrowDisabled from "../../images/svg/down-new-disabled.svg";
import downArrowFilled from "../../images/svg/down-new-white.svg";

import filterSave from "../../images/svg/filter-save.svg";
import filterSaveWhite from "../../images/svg/filter-save-white.svg";
import filterClear from "../../images/svg/filter-clear.svg";
import filterDelete from "../../images/svg/filter-delete.svg";
import filterSaveOff from "../../images/svg/filter-save-disabled.svg";
import filterClearOff from "../../images/svg/filter-clear-disabled.svg";
import filterDeleteOff from "../../images/svg/filter-delete-disabled.svg";

import { Snackbar } from '@material-ui/core';

export const usePrevNextButtons = (emblaApi) => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect).on("select", onSelect);
    new ResizeObserver(() => {
      if (emblaApi) {
        const isScrollable = emblaApi.internalEngine().scrollSnaps.length > 1;
        emblaApi.reInit({ active: isScrollable });
      }
    }).observe(document.body);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

const PrevButton = (props) => {
  const { children, ...restProps } = props;

  return (
    <div
      className="embla__button embla__button--prev embla__arrow-left-container"
      {...restProps}
    >
      <img
        src={filterArrow}
        className="embla__arrow arrow_left"
        style={{
          display: props.disabled ? "none" : "unset",
        }}
        alt="arrow-left"
      />
      {children}
    </div>
  );
};

const NextButton = (props) => {
  const { children, ...restProps } = props;

  return (
    <div className="embla__button embla__button--next" {...restProps}>
      <img
        src={filterArrow}
        className="embla__arrow"
        style={{
          display: props.disabled ? "none" : "unset",
        }}
        alt="arrow-right"
      />
      {children}
    </div>
  );
};

const FilterBar = (props) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ dragFree: true });
  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const applications = props.applications;
  const currentFilter = props.currentFilter;
  const applicationCount = props.applicationCount;
  const filterState = props.filterState;
  const setFilterState = props.setFilterState;
  const filterTypeSelected = props.filterTypeSelected;
  const setFilterTypeSelected = props.setFilterTypeSelected;
  const checkedCount = props.checkedCount;
  const setCheckedCount = props.setCheckedCount;

  const [savedFilterData, setSavedFilterData] = useState(localStorage.getItem('filterState'))
  const [savePopup, setSavePopup] = useState(false)
  const [justSaved, setJustSaved] = useState(false)

  const canSaveData = () => {
    if(!savedFilterData) {
      return checkedCount > 0
    }
    if(savedFilterData.selected !== props.filterTypeSelected) {
      return false;
    }
    const keys = Object.keys(props.filterState);

    return !keys.every(key => {
      const checked1 = props.filterState[key]
        .filter(item => item.checked)
        .map(item => item.value)
        .sort();

      const checked2 = savedFilterData[key]
        .filter(item => item.checked)
        .map(item => item.value)
        .sort();

      return JSON.stringify(checked1) === JSON.stringify(checked2);
    })
  }

  const getSavedFilter = () => {
    const filterString = localStorage.getItem('filterState')
    if(!filterString) return undefined;
    const JsonData = JSON.parse(filterString)
    setSavedFilterData(JsonData)
    return JsonData
  }

  const setSavedFilter = () => {
    setSavedFilterData({...props.filterState, selected: props.filterTypeSelected})
    const filterString = JSON.stringify({...props.filterState, selected: props.filterTypeSelected})
    localStorage.setItem('filterState', filterString)
    setSavePopup(true)
    setJustSaved(true)
  }

  const deleteSavedFilter = () => {
    localStorage.removeItem('filterState')
    setSavedFilterData(undefined)
    resetFilter()
  }

  useEffect(() => {
    const filterData = {
      posts: props.filterOptions.job_titles,
      locations: props.filterOptions.work_locations,
      interviewers: props.filterOptions.members,
    };

    const savedFilter = getSavedFilter();

    const savedFilterType = savedFilter ? savedFilter.selected : undefined;
    const savedCheck = savedFilter ? savedFilter[savedFilterType].filter(f => f.checked).map(f => f.value) : [];

    let saveCount = 0;

    Object.keys(filterData).forEach((key) => {
      filterData[key] = [...new Set(filterData[key])].map((value) => {
        if(savedFilterType === key && savedCheck.includes(value)) {
          saveCount++;
          return { value, checked: true };
        }
        return { value, checked: false };
      });
    });
    setFilterState(filterData);
    if(savedFilter) {
      setFilterTypeSelected(savedFilterType)
      setCheckedCount(saveCount)
    }
  }, [applications]);

  const jobBrackets = [
    {
      label: "全応募者",
      type: "ALL",
    },
    {
      label: "要対応",
      type: "URGENT",
    },
    {
      label: "確定待ち",
      type: "ESP",
    },
    {
      label: "面接確定",
      type: "IF",
    },
    {
      label: "採用",
      type: "SL",
    },
    {
      label: "不合格・辞退など",
      type: "CBS-CBE",
    },
  ];

  const [filterTypeDropdown, toggleFilterType] = useState(false);
  const [filterDetailDropdown, toggleDetailDropdown] = useState(false);
  const [filterInputText, setFilterInputText] = useState("");

  const filterTypes = [
    {
      type: "posts",
      label: "求人記事",
    },
    {
      type: "locations",
      label: "勤務地",
    },
    {
      type: "interviewers",
      label: "面接担当",
    },
  ];

  const selectFilterType = (ev, type) => {
    ev.preventDefault();
    ev.stopPropagation()
    if(filterTypeSelected && filterTypeSelected !== type) {
      const newFilterField = [...filterState[filterTypeSelected]].map(
        (option) => {
          return {
            value: option.value,
            checked: false,
          };
        }
      );
      setFilterState({ ...filterState, [filterTypeSelected]: newFilterField })
    }
    if(filterTypeSelected !== type) {
      setCheckedCount(0)
      setFilterTypeSelected(type);
      setFilterInputText("");
      setJustSaved(false)
    }
    toggleFilterType(false)
  };

  const toggleChecked = (value) => {
    if (!filterTypeSelected) return;
    const newFilterField = [...filterState[filterTypeSelected]].map(
      (option) => {
        if (option.value === value) {
          setCheckedCount(checkedCount + (option.checked ? -1 : 1))
          return {
            value,
            checked: !option.checked,
          };
        }
        return option;
      }
    );
    setFilterState({ ...filterState, [filterTypeSelected]: newFilterField });
    setJustSaved(false)
  };

  const resetFilter = () => {
    if(filterTypeSelected) {
      const newFilterField = [...filterState[filterTypeSelected]].map(
        (option) => {
          return {
            value: option.value,
            checked: false,
          };
        }
      );
      setFilterState({ ...filterState, [filterTypeSelected]: newFilterField })
      setCheckedCount(0)
      setFilterTypeSelected(undefined)
      setFilterInputText("")
      setJustSaved(false)
    }
  }

  useEffect(() => {
    if (props.currentFilter && emblaApi) {
      emblaApi.scrollTo(
        jobBrackets.findIndex((type) => type.type === props.currentFilter)
      );
    }
  }, [emblaApi, props.currentFilter]);

  return (
    <div className="filter-bar-box">
      <Snackbar 
            open={savePopup} 
            autoHideDuration={3000} 
            className='snackbar-filter'
            onClose={() => {
              setSavePopup(false)
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            message="フィルターを保存しました！"
          />
      <div className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="filter-bar-type-container embla__container">
            {jobBrackets.map((jobType) => (
              <div
                className="filter-type embla__slide"
                onClick={() => props.updateURL(jobType.type)}
              >
                <div className="filter-name">{jobType.label}</div>
                {applicationCount[jobType.type] > 0 && (
                  <div className="filter-count">
                    {applicationCount[jobType.type]}
                  </div>
                )}
                {jobType.type === currentFilter && (
                  <div className="filter-type-highlighter" />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>
      </div>
      <div className="filter-info-text">
        フィルター：求人記事や勤務地で応募者を絞り込むことができます。
      </div>
      <div className="filter-detail-container">
        <div className="filter-dropdown-container">
          <div
            className={`filter-dropdown${filterTypeSelected ? '__filled' : ''}`}
            style={{zIndex: 2}}
            onClick={() => toggleFilterType(!filterTypeDropdown)}
            onMouseEnter={() => toggleFilterType(true)}
            onMouseLeave={() => toggleFilterType(false)}
          >
            <div className="filter-dropdown-text">
              {filterTypeSelected
                ? filterTypes.find((f) => f.type === filterTypeSelected).label
                : "選択"}
            </div>
            <img
              src={filterTypeSelected ? downArrowFilled : downArrow}
              className="filter-dropdown-arrow"
              alt="down arrow"
              style={{
                transform: `scaleY(${filterTypeDropdown ? -1 : 1})`,
              }}
            />
            {filterTypeDropdown && (
              <div className="filter-dropdown-menu">
                <div className="filter-drop-down-main">
                  {filterTypes.map((filter) => (
                    <div
                      className="filter-item1"
                      onClick={(ev) => selectFilterType(ev, filter.type)}
                    >
                      {filter.label}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div
            className={`filter-dropdown${filterTypeSelected ? (checkedCount > 0 ? '__filled' : '') : '__disabled'} filter-dropdown__large`}
            onClick={() => {
              if (
                filterTypeSelected &&
                filterState[filterTypeSelected].length > 0
              ) {
                toggleDetailDropdown(!filterDetailDropdown);
              }
            }}
            onMouseEnter={() => {
              if (
                filterTypeSelected &&
                filterState[filterTypeSelected].length > 0
              ) {
                toggleDetailDropdown(true);
              }
            }}
            onMouseLeave={() => {
              toggleDetailDropdown(false)
              setFilterInputText('')
            }}
          >
            <div
              className={`filter-dropdown-text${
                filterTypeSelected ? "" : "__disabled"
              }`}
            >
              {checkedCount > 0 ? 
                filterState[filterTypeSelected].filter(option => option.checked).map(option => option.value).join(' - ')
                : '選択'
              }
            </div>
            <img
              src={filterTypeSelected ? (checkedCount > 0 ? downArrowFilled : downArrow) : downArrowDisabled}
              className="filter-dropdown-arrow"
              alt="down arrow"
              style={{
                transform: `scaleY(${filterDetailDropdown ? -1 : 1})`,
              }}
            />
            {filterTypeSelected &&
              filterDetailDropdown &&
              filterState[filterTypeSelected].length > 0 && (
                <div className="filter-dropdown-menu">
                  <div className="filter-drop-down-main">
                    <div className="search-box-container">
                      <div className="search-box">
                        <input
                          className="search-box-input"
                          placeholder="求人記事を検索"
                          value={filterInputText}
                          onClick={(ev) => {
                            ev.stopPropagation()
                            ev.preventDefault()
                          }}
                          onChange={(e) => setFilterInputText(e.target.value)}
                        />
                        <img
                          src={searchIcon}
                          className="search-box-icon"
                          alt="search icon"
                        />
                      </div>
                    </div>

                    <OverlayScrollbarsComponent
                      defer
                      className="filter-options"
                    >
                      {(filterInputText.length > 0
                        ? filterState[filterTypeSelected].filter((option) => {
                            return option.checked || option.value
                              .toLowerCase()
                              .includes(filterInputText.toLowerCase());
                          })
                        : filterState[filterTypeSelected]
                      ).sort((a, b) => b.checked - a.checked).map((option, index) => (
                        <div
                          className="filter-item2"
                          onClick={(ev) => {
                            ev.stopPropagation()
                            ev.preventDefault()
                            toggleChecked(option.value)
                          }}
                          style={
                            filterState[filterTypeSelected].length > 4
                              ? { marginRight: 24 }
                              : {}
                          }
                        >
                          {!option.checked ? (
                            <div className="checkbox"></div>
                          ) : (
                            <svg
                              className="checkbox-on"
                              xmlns="http://www.w3.org/2000/svg"
                              width="21"
                              height="21"
                              viewBox="0 0 21 21"
                              fill="none"
                            >
                              <rect
                                x="0.5"
                                y="0.869873"
                                width="20"
                                height="20"
                                rx="4"
                                fill="#33A8ED"
                              />
                              <path
                                d="M7.58257 14.7784L3.78673 10.9826L2.49414 12.266L7.58257 17.3545L18.5059 6.43119L17.2224 5.14771L7.58257 14.7784Z"
                                fill="white"
                              />
                            </svg>
                          )}

                          <div className="value">{option.value}</div>
                        </div>
                      ))}
                    </OverlayScrollbarsComponent>
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className="filter-action-container">
          <div className="filter-action-box__save">
            <div
              onClick={!justSaved && canSaveData() && setSavedFilter}
              className={`filter-action-button${justSaved ? '__filled' : (canSaveData() ? '' : '__disabled')}`}
            >
              <svg className='filter-action-icon' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                <path
                    d="M20.6714 8.17514L16.6008 4.10545C16.3196 3.82514 15.9464 3.67139 15.5527 3.67139H4.60645C3.77957 3.67139 3.10645 4.34451 3.10645 5.17139V20.1714C3.10645 20.9983 3.77957 21.6714 4.60645 21.6714H19.6064C20.4333 21.6714 21.1064 20.9983 21.1064 20.1714V9.2317C21.1074 8.83889 20.9489 8.45451 20.6714 8.17514ZM15.8564 20.1714H8.35645V14.9214H15.8564V20.1714ZM19.6064 20.1714H17.3564V14.9214C17.3564 14.0945 16.6833 13.4214 15.8564 13.4214H8.35645C7.52957 13.4214 6.85645 14.0945 6.85645 14.9214V20.1714H4.60645V5.17139L15.5414 5.16764L19.6064 9.23264V20.1723V20.1714Z"
                    fill={justSaved ? "white" : (canSaveData() ? "#33A8ED" : "#B8B8B8")} />
                <path
                    d="M14.3564 6.67139H9.10645C8.69207 6.67139 8.35645 7.00701 8.35645 7.42139C8.35645 7.83576 8.69207 8.17139 9.10645 8.17139H14.3564C14.7708 8.17139 15.1064 7.83576 15.1064 7.42139C15.1064 7.00701 14.7708 6.67139 14.3564 6.67139Z"
                    fill={justSaved ? "white" : (canSaveData() ? "#33A8ED" : "#B8B8B8")} />
              </svg>
              <div className="filter-action-text">
                フィルターを保存する
              </div>
            </div>
          </div>
          <div className="filter-action-box">
            <div
              onClick={resetFilter}
              className={`filter-action-button${filterTypeSelected ? '' : '__disabled'}`}
            >
              <svg className='filter-action-icon' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.52344 5.20638C3.92039 5.20638 4.24219 5.52817 4.24219 5.92513V8.80012H7.11719C7.51414 8.80012 7.83594 9.12192 7.83594 9.51887C7.83594 9.91583 7.51414 10.2376 7.11719 10.2376H3.52344C3.12648 10.2376 2.80469 9.91583 2.80469 9.51887V5.92513C2.80469 5.52817 3.12648 5.20638 3.52344 5.20638Z"
                  fill={filterTypeSelected ? "#33A8ED" : "#B8B8B8"} />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.4857 3.60277C12.159 3.2694 13.8936 3.43979 15.47 4.09239C17.0464 4.74499 18.3939 5.85048 19.3419 7.269C20.2899 8.68753 20.796 10.3554 20.796 12.0615C20.796 13.7677 20.2899 15.4355 19.3419 16.8541C18.3939 18.2726 17.0464 19.3781 15.47 20.0307C13.8936 20.6833 12.159 20.8537 10.4857 20.5203C8.81245 20.1869 7.27563 19.3648 6.06968 18.1578C5.78911 17.877 5.7893 17.4219 6.0701 17.1414C6.3509 16.8608 6.80599 16.861 7.08657 17.1418C8.09152 18.1476 9.3722 18.8327 10.7666 19.1105C12.161 19.3883 13.6065 19.2463 14.9202 18.7025C16.2338 18.1586 17.3567 17.2374 18.1467 16.0553C18.9368 14.8732 19.3585 13.4833 19.3585 12.0615C19.3585 10.6397 18.9368 9.24986 18.1467 8.06776C17.3567 6.88565 16.2338 5.96441 14.9202 5.42058C13.6065 4.87675 12.161 4.73476 10.7666 5.01256C9.3722 5.29037 8.09152 5.9755 7.08657 6.98127L7.08561 6.98223L4.03092 10.0279C3.74982 10.3082 3.29473 10.3075 3.01446 10.0264C2.73418 9.74533 2.73485 9.29024 3.01595 9.00997L6.06968 5.96522C7.27556 4.75855 8.81267 3.93609 10.4857 3.60277Z"
                  fill={filterTypeSelected ? "#33A8ED" : "#B8B8B8"} />
              </svg>
              {/* {filterTypeSelected ? <img src={filterClear} className='filter-action-icon' alt='filter clear'/> :
              <img src={filterClearOff} className='filter-action-icon' alt='filter clear'/>} */}
              <div className="filter-action-text">
                選択をクリアする
              </div>
            </div>
            <div
              onClick={savedFilterData && deleteSavedFilter}
              className={`filter-action-button${savedFilterData ? '' : '__disabled'}`}
            >
              <svg className='filter-action-icon' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12.9399 2.17623C7.33213 2.17623 2.7861 6.72226 2.7861 12.3301C2.7861 17.9379 7.33213 22.4839 12.9399 22.4839C18.5478 22.4839 23.0938 17.9379 23.0938 12.3301C23.0938 6.72226 18.5478 2.17623 12.9399 2.17623ZM0.939941 12.3301C0.939941 5.70266 6.31252 0.330078 12.9399 0.330078C19.5674 0.330078 24.9399 5.70266 24.9399 12.3301C24.9399 18.9575 19.5674 24.3301 12.9399 24.3301C6.31252 24.3301 0.939941 18.9575 0.939941 12.3301Z"
                      fill={savedFilterData ? "#33A8ED" : "#B8B8B8"} />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M17.285 7.98506C17.6454 8.34554 17.6454 8.93 17.285 9.29048L9.90035 16.6751C9.53986 17.0356 8.9554 17.0356 8.59492 16.6751C8.23444 16.3146 8.23444 15.7302 8.59492 15.3697L15.9795 7.98506C16.34 7.62457 16.9245 7.62457 17.285 7.98506Z"
                      fill={savedFilterData ? "#33A8ED" : "#B8B8B8"} />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M8.59492 7.98506C8.9554 7.62457 9.53986 7.62457 9.90035 7.98506L17.285 15.3697C17.6454 15.7302 17.6454 16.3146 17.285 16.6751C16.9245 17.0356 16.34 17.0356 15.9795 16.6751L8.59492 9.29048C8.23444 8.93 8.23444 8.34554 8.59492 7.98506Z"
                      fill={savedFilterData ? "#33A8ED" : "#B8B8B8"} />
              </svg>
              {/* {savedFilterData ? <img src={filterDelete} className='filter-action-icon' alt='filter delete'/> : 
              <img src={filterDeleteOff} className='filter-action-icon' alt='filter delete'/>} */}
              <div className="filter-action-text">
                フィルターを削除する
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
