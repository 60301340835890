import React from 'react';
import DashboardTranslations from '../../DashboardTranslations.js';
import '../styles/sidebar.scss';
import FontAwesomeIcon from 'react-fontawesome'
import Joyride from 'react-joyride';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { logout } from '../../actions';
import { completedJoyride } from '../../actions';
import { getUrgentApplicationCount, notificationDataAPI } from '../helperFunctions/dataServices.js'
import WORKJAPANSidebarIcon from '../../images/WORKJAPAN-sidebar-icon.png'
import CalendarIcon from '../../images/sidebar-calendar.png'
import RecieptIcon from '../../images/sidebar-reciept.png'
import SettingsIcon from '../../images/sidebar-settings.png'
import SettingsIconHover from '../../images/sidebar-settings-hover.png'
import ChartIcon from '../../images/sidebar-chart.png'
import faqIcon from '../../images/sidebar-faq.png';
import envelopeIcon from '../../images/sidebar-envelope.png';
import externalLinkIcon from '../../images/external-link.png';
import urgentIcon from "../../images/svg/urgent.svg";

// steps: [
//   {
//     target: '.jobs-interviews-button',
//     content: '応募者が面接を予約すると、メールやSMSで通知します。※SMS通知をご希望の方は、携帯電話のご登録をお忘れなく！',
//     title: '応募者ごとの面接予約状況や ステータス管理ができる画面です。',
//     // placement: "right",
//     controlled: true,
//     disableBeacon: true,
//   },
// ],


class Sidebar extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        type: 'normal',
        notificationDataCount: 0,
        hovered: false,
        mainKey: 0,
        steps: [],
        urgentCount: null,
      }
    }

    hasOnlineIVDetails(application) {
      return !(!application.online_iv_detail && application.status_v2 === 'IF')
    }

    fetchApplications() {
      getUrgentApplicationCount().then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          if(response.data && response.data.urgent_count !== undefined) {
            this.setState({urgentCount: response.data.urgent_count})
          }
        }
      })
    }

    setRoute(route) {
      // this.props.dispatch(selectedScreen(route))
      if (route === "logout") {
        this.props.dispatch(logout())
        this.props.history.push('/register')
      }
      if (this.props.closeDrawer) {
        this.props.closeDrawer()
      }
      if (route !== "logout") {
        this.props.history.push('/dashboard/'+route)
      }
      // return true;
    }

    componentWillMount() {
      // var header = this.props.history.location.pathname.replace('/dashboard/', '')
      // if (header.indexOf('-') >= 0) {
      //     header = header.replace("-", " ");
      // }
      // this.props.dispatch(selectedScreen(header))
    }

    handleJoyrideCallback(data) {
      if (data.action === "next" && data.lifecycle === "complete" && data.status === "finished") {
        var joyride = this.props.joyride.completed
        if (joyride.indexOf("sidebar") > -1) {
          joyride = joyride.filter(item => item !== "sidebar")
        }
        else {
          joyride[joyride.length] = "sidebar"
        }
        this.props.dispatch(completedJoyride(joyride));
      }
    }

    restartJoyride() {
      var steps= [
        {
          target: '.jobs-interviews-button',
          content: '勤務地や面接場所は、一度登録すると何度でも使えます。',
          title: '求人記事を作成しましょう。',
          disableBeacon: true,
        },
      ]
      if (this.props.registerStep !== null && this.props.registerStep !== "null") {
        this.setState({
          mainKey: this.state.mainKey + 1,
          steps: steps
        });
      }
    }

    componentWillUnmount() {
      var element3 = document.getElementById('sidebar-main-container')
      if (this.props.remove === true) {
        element3.classList.remove('remove-sidebar-main')
      }
      else {
        // element3.classList.remove('remove-fixed')
      }
    }

    hideModal() {
      this.setState({showContactUs: false})
    }

    checkForJoyride() {
      if (this.props.joyride.completed.indexOf("sidebar") > -1) {
        if (this.props.registerStep !== null && this.props.registerStep !== "null") {
          this.restartJoyride()
        }
      }
    }

    componentDidMount() {
      if (!this.props.isLoggedIn) {
        this.props.history.push('/register')
      }
      this.notificationData()
      this.fetchApplications()
      if (this.props.joyride.completed.indexOf("sidebar") > -1) {
        if (this.props.registerStep !== null && this.props.registerStep !== "null") {
          this.setState({steps: []});
        }
      }
      // var element = document.getElementById('footer')
      // element.classList.add('remove-footer')
      // var element2 = document.getElementsByClassName('navbar-expand-md')
      // element2[0].className += ' remove-navbar-expand-md'
      var element3 = document.getElementById('sidebar-main-container')
      if (this.props.remove === true) {
        element3.classList.add('remove-sidebar-main')
      }
      else {
        // element3.classList.add('remove-fixed')
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if(this.state && this.props && this.state.urgentCount != null) {
        if (prevProps.urgentUpdate !== this.props.urgentUpdate) {
          this.fetchApplications();
        }
      }
    }

    notificationData() {
      this.setState({loading: true})
      notificationDataAPI().then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          var count = 0
          response.data.map(item => {
            if (item.is_read === false) {
              count = count + 1
            }
          })
          this.setState({notificationDataCount: count, loading: false})
        }
        else {
          this.setState({loading: false})
        }
      })
      .catch((e) => {
        this.setState({loading: false})
      })
    }


    hovered() {
      this.setState({hovered: true})
    }

    out() {
      this.setState({hovered: false})
    }

    render() {
      var { selectedScreen } = this.props
      var { steps, mainKey, notificationDataCount, urgentCount } = this.state;

      var page = "jobs"
      if (this.props.history.location.pathname.indexOf('/dashboard/team') > -1) {
        page = "team"
      }
      if (this.props.history.location.pathname.indexOf('/dashboard/applicants') > -1) {
        page = "applicants"
      }
      else if (this.props.history.location.pathname.indexOf('/dashboard/billing') > -1) {
        page = "billing"
      }
      else if (this.props.history.location.pathname.indexOf('/dashboard/company-profile') > -1) {
        page = "company"
      }
      else if (this.props.history.location.pathname.indexOf('/dashboard/user-settings') > -1) {
        page = "user"
      }
      else if (this.props.history.location.pathname.indexOf('/dashboard/interview-times') > -1) {
        page = "interview-times"
      }
        return (
          <div id="sidebar-main-container" className="sidebar-container">
            <div className="sidebar-inner">
              <div className="sidebar-logo-container">
                <div className="sidebar-logo">
                  <a href="https://www.workjapan.jp/job-site-japan" target="_blank" rel="noopener noreferrer" >
                    <img src={WORKJAPANSidebarIcon} alt="work-japan-logo" width= "90%" style={{margin: '0px auto'}}/>
                  </a>
                  <FontAwesomeIcon
                    name="close" onClick={() => this.props.closeDrawer()} className="icon"/>
                </div>
              </div>
              <div className="sidebar-options-container">
                <Joyride
                  autoStart={true}
                  run={true}
                  key={mainKey}
                  steps={steps}
                  continuous={true}
                  disableCloseOnEsc={true}
                  disableOverlayClose={true}
                  locale={{ back: '前へ', close: '閉じる', last: '閉じる', next: '次へ', skip: 'スキップ' }}
                  showStepsProgress={true}
                  callback={this.handleJoyrideCallback.bind(this)}
                />

                <div className="notification-redirect" onClick={()=>this.setRoute('notifications')}>
                  お知らせが{notificationDataCount}件あります
                </div>
                <div
                  style={{borderLeft: page === "jobs" ? "3px solid #FFFFFF" : "3px solid transparent"}}
                  className="level-1 jobs-interviews-button" onClick={()=>this.setRoute('jobs')}>
                  <img src={ChartIcon} alt="calendar-icon" width= "14px"/>
                  <div style={{backgroundColor: selectedScreen === 'calendar' ? '#4C9689' : 'transparent'}} className="options">{DashboardTranslations.sidebar_jobs_text}</div>
                </div>
                <div
                  style={{borderLeft: page === "applicants" ? "3px solid #FFFFFF" : "3px solid transparent"}}
                  className="level-1" onClick={()=>this.setRoute('applicants')}>
                  <img src={CalendarIcon} alt="calendar-icon" width= "14px"/>
                  <div style={{width: '100%', backgroundColor: selectedScreen === 'applicants' ? '#4C9689' : 'transparent'}} className="options">
                    {DashboardTranslations.sidebar_applicants_text}
                    {(urgentCount != null && urgentCount !== 0) && <div className='icon-container'>
                      <img src={urgentIcon} height={20} alt='urgent-icon' />
                      {`${urgentCount}`}
                    </div>}
                  </div>
                </div>
                <div
                  style={{borderLeft: page === "billing" ? "3px solid #FFFFFF" : "3px solid transparent"}}
                  className="level-1" onClick={()=>this.setRoute('billing')}>
                  <img src={RecieptIcon} alt="calendar-icon" width= "14px"/>
                  <div style={{backgroundColor: selectedScreen === 'locations' ? '#4C9689' : 'transparent'}} className="options">{DashboardTranslations.sidebar_billing_text}</div>
                </div>
                <div className="level-1-special-container" onMouseOver={this.hovered.bind(this)} onMouseOut={this.out.bind(this)}>
                  <div
                    style={{borderLeft: page === "user" ? "3px solid #FFFFFF" : "3px solid transparent"}}
                    className="level-1-hover-parent">
                    {!this.state.hovered && <img src={SettingsIcon} alt="calendar-icon" width= "14px"/>}
                    {this.state.hovered && <img src={SettingsIconHover} alt="calendar-icon" width= "14px"/>}
                    <div style={{backgroundColor: selectedScreen === 'settings' ? '#4C9689' : 'transparent'}} className="options">{DashboardTranslations.sidebar_main_settings_text}</div>
                  </div>
                  <div
                    style={{borderLeft: page === "team" ? "3px solid #FFFFFF" : "3px solid transparent"}}
                    className="level-1-hover-child" onClick={()=>this.setRoute('team')}>
                    <div style={{backgroundColor: selectedScreen === 'interviews' ? '#4C9689' : 'transparent'}} className="options">{DashboardTranslations.sidebar_teams_text}</div>
                  </div>
                  <div
                    style={{borderLeft: page === "company" ? "3px solid #FFFFFF" : "3px solid transparent"}}
                    className="level-1-hover-child" onClick={()=>this.setRoute('company-profile')}>
                    <div style={{backgroundColor: selectedScreen === 'profile' ? '#4C9689' : 'transparent'}} className="options">{DashboardTranslations.sidebar_company_profile_text}</div>
                  </div>
                  <div
                    style={{borderLeft: page === "team" ? "3px solid #FFFFFF" : "3px solid transparent"}}
                    className="level-1-hover-child" onClick={()=>this.setRoute('user-settings')}>
                    <div style={{backgroundColor: selectedScreen === 'interviews' ? '#4C9689' : 'transparent'}} className="options">{DashboardTranslations.sidebar_user_settings_text}</div>
                  </div>
                  <div href="/register" className="level-1-hover-child" onClick={()=>this.setRoute('logout')}>
                    <div style={{backgroundColor: selectedScreen === 'profile' ? '#4C9689' : 'transparent'}} className="options">{DashboardTranslations.sidebar_logout_text}</div>
                  </div>
                </div>

                <a href="https://workjapan.notion.site/ea58ca368ca4402fb6535e6565ba3672?v=7ebac96252d84e4ca5580f57cfbc466a" target="_blank" style={{ textDecoration: 'none' }}>
                  <div style={{borderLeft: page === "billing" ? "3px solid #FFFFFF" : "3px solid transparent"}} className="level-1">
                    <img src={faqIcon} alt="calendar-icon" width= "14px"/>
                    <div style={{backgroundColor: selectedScreen === 'locations' ? '#4C9689' : 'transparent'}} className="options">ヘルプ</div>
                    <img src={externalLinkIcon} style={{ marginLeft: 10 }} alt="calendar-icon" width= "14px"/>
                  </div>
                </a>

                <a href="https://www.workjapan.jp/inquiry/" target="_blank" style={{ textDecoration: 'none' }}>
                  <div style={{borderLeft: page === "billing" ? "3px solid #FFFFFF" : "3px solid transparent"}} className="level-1">
                    <img src={envelopeIcon} alt="calendar-icon" width= "14px"/>
                    <div style={{backgroundColor: selectedScreen === 'locations' ? '#4C9689' : 'transparent'}} className="options">お問合せ</div>
                    <img src={externalLinkIcon} style={{ marginLeft: 10 }} alt="calendar-icon" width= "14px"/>
                  </div>
                </a>
              </div>
            </div>
          </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedScreen: state.selectedScreen,
        isLoggedIn: state.user.isLoggedIn,
        language: state.language,
        token: state.user.token,
        joyride: state.joyride,
        registerStep: state.user.registerStep,
    }
};

Sidebar = connect(mapStateToProps)(Sidebar);

export default withRouter(Sidebar);
